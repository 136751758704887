import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { v4 } from "uuid";
import moment from "moment";
import { axios } from "../../../../../App";
import qs from "qs";
import { DatePicker, Empty, InputNumber } from "antd";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    urgency_level: ["Low", "Medium", "High"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const [materialItems, setMaterialItems] = useState([]);
  const [paymentSchedules, setPaymentSchedules] = useState([]);
  const [terms, setTerms] = useState([]);
  const contentTypes = [
    {
      slug: "material-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_group: data,
        })),
    },
    {
      slug: "material-items",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_item: data,
        })),
    },
    {
      slug: "material-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_unit: data,
        })),
    },
    {
      slug: "vendors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendors: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
        })),
    },
    {
      slug: "vendor-terms",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: item?.heading,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor_term: data,
        })),
    },
    {
      slug: "locations",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.location_type?.data?.attributes?.name})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          delivery_address: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          //  approved_by: data,
        })),
    },
    {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          approved_by: data,
        })),
    },
    {
      slug: "vendor-boqs",
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor_boq: data,
        })),
    },
  ];

  // MATERIAL ITEMS

  const transformMaterialItem = (rawData) => {
    return {
      ...rawData,
      material_group: values?.material_group?.find(
        (item) => item?.value === rawData?.material_group
      )?.label,
      material_item: values?.material_item?.find(
        (item) => item?.value === rawData?.material_item
      )?.label,
      material_unit: values?.material_unit?.find(
        (item) => item?.value === rawData?.material_unit
      )?.label,
      raw_data: rawData,
    };
  };

  const onAddMaterialItem = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformMaterialItem(data),
    };

    setMaterialItems((prev) => prev?.concat(newData));
  };

  const onAddExistingMaterialItem = (data) => {
    const raw = {
      material_group: data?.material_group?.data?.id,
      material_item: data?.material_item?.data?.id,
      material_unit: data?.material_unit?.data?.id,
      quantity: data?.quantity,
      // amount: data?.amount,
    };

    const newData = {
      id: v4(),
      ...raw,
      ...transformMaterialItem(raw),
    };

    setMaterialItems((prev) => prev?.concat(newData));
  };

  const onEditMaterialItem = (id, data) => {
    let newItems = materialItems?.map((record) => {
      if (record?.id === id) {
        record = {
          ...record,
          ...transformMaterialItem(data),
        };
      }

      return record;
    });

    setMaterialItems(newItems);
  };

  const onDeleteMaterialItem = (id) => {
    setMaterialItems((prev) => prev?.filter((item) => item?.id !== id));
  };

  // PAYMENT SCHEDULES

  const transformPaymentSchedule = (rawData) => {
    return {
      ...rawData,
      scheduled_date: moment(rawData?.scheduled_date)?.format("DD-MM-YYYY"),
      raw_data: rawData,
    };
  };

  const onAddPaymentSchedule = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setPaymentSchedules((prev) => prev?.concat(newData));
  };

  const onAddExistingPaymentSchedule = (data) => {
    const raw = {
      scheduled_date: data?.scheduled_date,
      amount: data?.amount,
      description: data?.description,
    };

    const newData = {
      id: v4(),
      ...raw,
      ...transformPaymentSchedule(raw),
    };

    setPaymentSchedules((prev) => prev?.concat(newData));
  };

  const onEditPaymentSchedule = (id, data) => {
    let newItems = paymentSchedules?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setPaymentSchedules(newItems);
  };

  const onDeletePaymentSchedule = (id) => {
    setPaymentSchedules((prev) => prev?.filter((item) => item?.id !== id));
  };

  // TERMS

  const transformTerm = (rawData) => {
    return {
      ...rawData,
      material_group: values?.material_group?.find(
        (item) => item?.value === rawData?.material_group
      )?.label,
      material_item: values?.material_item?.find(
        (item) => item?.value === rawData?.material_item
      )?.label,
      material_unit: values?.material_unit?.find(
        (item) => item?.value === rawData?.material_unit
      )?.label,
      raw_data: rawData,
    };
  };

  const onAddTerm = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformTerm(data),
    };

    setTerms((prev) => prev?.concat(newData));
  };

  const onAddExistingTerm = (data) => {
    const raw = {
      heading: data?.heading,
      material_group: data?.material_group?.data?.id,
      material_item: data?.material_item?.data?.id,
      material_unit: data?.material_unit?.data?.id,
      remarks: data?.remarks,
    };

    const newData = {
      id: v4(),
      ...raw,
      ...transformTerm(raw),
    };

    setTerms((prev) => prev?.concat(newData));
  };

  const onEditTerm = (id, data) => {
    let newItems = terms?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformTerm(data),
        };
      }

      return record;
    });

    setTerms(newItems);
  };

  const onDeleteTerm = (id) => {
    setTerms((prev) => prev?.filter((item) => item?.id !== id));
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVendorSchedulesData = async (params = null) => {
    try {

      const queryObject = {
        populate: "*",
        // sort: ["heading:asc"],
      };

      // if (!_.isEmpty(params)) {
      //   queryObject["filters"] = params;
      // }

      const response = await axios.get(
        `vendor-payment-schedules?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        // Create a set of material_group IDs from materialItems
        const materialGroupIds = new Set(materialItems?.map(item => item.raw_data.material_group));

        // Filter the data based on material_group IDs
        const filtered_data = response?.data?.data?.filter(
          (s) => materialGroupIds?.has(s?.attributes?.material_group?.data?.id)
        );

        // console.log('Material IDS', materialGroupIds, response?.data?.data, filtered_data)

        if (filtered_data) {
          // Extract all payment schedules from the filtered data
          const payments = filtered_data?.flatMap(payment =>
            payment?.attributes?.payment_schedule?.map(schedule => ({
              ...schedule,
              material_group: payment.attributes.material_group
            }))
          );

          // Do something with the payments, e.g., set state
          setPaymentSchedules(payments);
        }

      }

    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle date change
  const handleDateChange = (date, dateString, id) => {
    setPaymentSchedules((prevSchedules) =>
      prevSchedules.map((schedule) =>
        schedule.id === id ? { ...schedule, date: dateString } : schedule
      )
    );
  };

  useEffect(() => {
    fetchVendorSchedulesData();

  }, [materialItems])

  console.log('Vendor material Add', paymentSchedules);

  return (
    <AddForm
      config={config}
      values={values}
      modals={{
        material_items: {
          data: materialItems,
          methods: {
            add: onAddMaterialItem,
            edit: onEditMaterialItem,
            delete: onDeleteMaterialItem,
            addExisting: onAddExistingMaterialItem,
          },
        },
        // payment_schedules: {
        //   data: paymentSchedules,
        //   // methods: {
        //   //   add: onAddPaymentSchedule,
        //   //   edit: onEditPaymentSchedule,
        //   //   delete: onDeletePaymentSchedule,
        //   //   addExisting: onAddExistingPaymentSchedule,
        //   // },
        // },
        terms: {
          data: terms,
          methods: {
            add: onAddTerm,
            edit: onEditTerm,
            delete: onDeleteTerm,
            addExisting: onAddExistingTerm,
          },
        },
      }}
      data={{
        material_items: materialItems,
        payment_schedules: paymentSchedules,
        terms,
      }}
      onFieldChange={{
        vendor_boq: (antForm) => {
          const boqId = antForm.getFieldValue("vendor_boq");
          const boq = values?.vendor_boq?.find(data => data.value === boqId)


          if (boq) {
            setMaterialItems(boq?.boq_item?.map(data => ({
              ...data,
              material_group: values?.material_group?.find(group => group.value === data.material_group)?.label,
              material_item: values?.material_item?.find(item => item.value === data.material_item)?.label,
              material_unit: values?.material_unit?.find(unit => unit.value === data.material_unit)?.label,
              raw_data: data,
            })))
          }

        },

        vendor_term: (antForm) => {
          const termId = antForm.getFieldValue("vendor_term");
          const term = values?.vendor_term?.filter(data => data.value === termId)

          if (term) {
            setTerms(term?.map(data => ({
              ...data,
              material_group: values?.material_group?.find(group => group.value === data.material_group?.data?.id)?.label,
              material_item: values?.material_item?.find(item => item.value === data.material_item?.data?.id)?.label,
              raw_data: data,
            })))
          }
        },
      }}
    >
      <div className="public-form-section">
        <h2 className="public-form-section-title">Payment Schedules</h2>

        <div style={{ overflowX: "auto" }}>
          <table className="public-form-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Material Group</th>
                <th>Description</th>
                <th>Amount %</th>
                <th style={{ width: '20%' }}>Date</th>
              </tr>
            </thead>

            <tbody>
              {paymentSchedules?.length > 0 &&
                paymentSchedules?.map((data, index) => {

                  return (
                    <tr key={data?.id}>
                      <td className="text-center text-bold">{index + 1}</td>
                      <td>{data?.name || "N/A"}</td>
                      <td>{data?.material_group?.data?.attributes?.name || "N/A"}</td>
                      <td>{data?.description}</td>
                      <td>{data?.amount_percentage}</td>
                      <td>
                        <DatePicker
                          onChange={(date, dateString) =>
                            handleDateChange(date, dateString, data.id)
                          }
                          value={data.date ? moment(data.date, "YYYY-MM-DD") : null}
                        />
                      </td>
                    </tr>
                  );
                })}

              {paymentSchedules?.length === 0 && (
                <tr>
                  <td colSpan={11} style={{ padding: 24 }}>
                    <Empty />
                  </td>
                </tr>
              )}


            </tbody>
          </table>
        </div>
      </div>
    </AddForm>
  );
};

export default Page;
