import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";
import { Breadcrumb, Button, Col, Empty, Form, Input, PageHeader, Row, Space, notification } from "antd";
import { Helmet } from "react-helmet";
import { Box, Stack } from "@chakra-ui/react";
import FormElement from "../../../components/FormElement";
import { v4 } from "uuid";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [allValues, setAllValues] = useState({});

  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "work-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_group: data,
        })),
    },
    // {
    //   slug: "material-items",
    //   transformer: (item) => ({
    //     value: item?.id,
    //     label: item?.name,
    //   }),
    //   onSuccess: (data) =>
    //     setValues((prev) => ({
    //       ...prev,
    //       material_item: data,
    //     })),
    // },
    // {
    //   slug: "material-units",
    //   transformer: (item) => ({
    //     value: item?.id,
    //     label: item?.name,
    //   }),
    //   onSuccess: (data) =>
    //     setValues((prev) => ({
    //       ...prev,
    //       material_unit: data,
    //     })),
    // },
    {
      slug: "sub-contractors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractor: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          //  approved_by: data,
        })),
    },
    {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          approved_by: data,
        })),
    },
  ];

  const handleValuesChange = (_, inputValues) => {
    setAllValues((prev) => ({
      ...prev,
      ...inputValues,
    }));
  };

  const handleFinish = async (values) => {
    setSubmitting(true);

    const all_data = {
      ...values,
      payment_schedule: schedules

    }

    const response = await config?.actions?.onFinish(all_data, allValues);

    if (response) {
      if (response?.success) {
        notification["success"]({
          message: config?.title,
          description: response?.message,
        });

        config?.actions?.onBack();
      } else {
        notification["error"]({
          message: config?.title,
          description: response?.message,
        });
      }
    }

    setSubmitting(false);
  };

  useEffect(() => {
    form.setFieldsValue(allValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues]);

  const onAddSchedule = (data) => {

    setSchedules((prev) =>
      prev?.concat({
        id: v4(),
        name: '',
        description: '',
        amount_percentage: 0,
        amount: 0,
      })
    );
  };

  const onEditSchedule = (id, key, value) => {
    setSchedules((prev) =>
      prev?.map((item) => {
        if (item?.id === id) {
          item[key] = value;
        }

        return item;
      })
    );
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setAllValues({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            project: response?.data?.data?.attributes?.project?.data?.id,
            work_group:
              response?.data?.data?.attributes?.work_group?.data?.id,
            sub_contractor:
              response?.data?.data?.attributes?.sub_contractor?.data?.id,
            scheduled_date: moment(
              response?.data?.data?.attributes?.scheduled_date
            ),
            approved_by:
              response?.data?.data?.attributes?.approved_by?.data?.id,
          });

          setSchedules(response?.data?.data?.attributes?.payment_schedule)

        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    // <AddForm config={config} values={values} />
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      <Form
        form={form}
        name={config?.id}
        layout="vertical"
        onFinish={handleFinish}
        style={styles.form}
        scrollToFirstError
        onValuesChange={handleValuesChange}
      >
        <Row gutter={24}>
          <Box display={{ base: "none", md: "block", lg: "block" }}>
            <Row gutter={24}>
              {config?.components.map((component) => (
                <Col span={component?.span} key={component?.name}>
                  <FormElement
                    {...component}
                    values={values}
                    form={form}
                  // onFieldChange={onFieldChange?.[component?.name]}
                  // disabled={disabled ? disabled : loading}
                  />
                </Col>
              ))}
            </Row>
          </Box>

          <Col span={24}>
            <div style={styles.header}>
              <h3 style={styles.title}>
                Schedules of Payments
              </h3>

              <Space>
                <Button type="primary" onClick={onAddSchedule}>
                  New Item
                </Button>
              </Space>
            </div>
            <div style={{ overflow: "auto" }}>
              <table className="public-form-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th style={{ width: "40%", textAlign: "left" }}>Description</th>
                    <th style={{ width: "20%", textAlign: "left" }}>
                      Amount %
                    </th>
                    {/* <th style={{ width: "20%", textAlign: "left" }}>Notes</th> */}
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>

                <tbody>
                  {schedules?.length === 0 && (
                    <tr>
                      <td colSpan={7}>
                        <div style={styles.loaderContainer}>
                          <Empty />
                        </div>
                      </td>
                    </tr>
                  )}

                  {schedules?.length > 0 &&
                    schedules?.map((schedule, index) => (
                      <tr key={schedule?.id}>
                        <td style={{ fontWeight: "bold" }}>{index + 1}</td>
                        <td>
                          <Input
                            placeholder="Enter Name"
                            type="text"
                            value={schedule?.name}
                            onChange={(e) =>
                              onEditSchedule(schedule?.id, "name", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <Input
                            placeholder="Enter Description"
                            type="text"
                            value={schedule?.description}
                            onChange={(e) =>
                              onEditSchedule(schedule?.id, "description", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <Input
                            placeholder="Enter amount %"
                            type="number"
                            min={0}
                            max={100}
                            value={schedule?.amount_percentage}
                            onChange={(e) =>
                              onEditSchedule(schedule?.id, "amount_percentage", e.target.value)
                            }
                          />
                        </td>
                        {/* <td>
                          <Input
                            placeholder="Enter notes"
                            value={schedule?.notes}
                            onChange={(e) =>
                              onEditSchedule(schedule?.id, "notes", e.target.value)
                            }
                          />
                        </td> */}
                        {/* <td>
                          <Popconfirm
                            title={`Are you sure to remove this item?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => onRemoveIssue(schedule?.id)}
                          >
                            <Tooltip placement="bottom" title={`Remove Item`}>
                              <Button type="danger" icon={<DeleteOutlined />} />
                            </Tooltip>
                          </Popconfirm>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Col>

          {config?.components?.length > 0 && (
            <Box display={{ base: "block", md: "none", lg: "none" }}>
              {config?.components.map((component) => {
                return (
                  <Stack span={component?.span} key={component?.name}>
                    <FormElement
                      {...component}
                      values={values}
                      form={form}
                    />
                  </Stack>
                );
              })}
            </Box>
          )}

          {/* <Errors errors={errors?.issues} /> */}
        </Row>

        <Space style={styles.buttons}>
          <Space>
            <Button onClick={config?.actions?.onBack}>Cancel</Button>

            <Button type="primary" htmlType="submit" loading={submitting}>
              Save
            </Button>
          </Space>
        </Space>
      </Form>
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
  },
  loaderContainer: {
    textAlign: "center",
    padding: 12,
  },
  header: {
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  errors: {
    marginBottom: 24,
  },
  errorsTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 6,
  },
  alert: {
    marginBottom: 6,
  },
  pageTitle: {
    whiteSpace: "normal", // Allow text to wrap to the next line
    overflow: "hidden",
  },
};

export default Page;
