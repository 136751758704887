import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input, Select } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";
import { ReportDataTable } from "../../../../stocks/components";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const BalanceToPay = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`projects`);

      if (response?.data) {
        let options = [
          {
            value: "",
            label: "All Projects",
          },
        ];

        response?.data?.data?.forEach((record) => {
          options?.push({
            value: record?.id,
            label: record?.attributes?.name,
          });
        });

        setProjects(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      // console.log('budgets', response?.data?.data)

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => {
            const phases = item?.attributes?.phase?.data || [];

            const currentPhaseIndex = phases.length - 1;
            const previousPhaseIndex = phases.length - 2;

            const currentPhase = phases?.[0]?.attributes;
            const previousPhase = phases[previousPhaseIndex]?.attributes;

            const current_total_cost = currentPhase
              ? currentPhase.total_actual_budget + currentPhase.total_estimated_budget
              : null;

            const previous_total_cost = previousPhase
              ? previousPhase.total_actual_budget + previousPhase.total_estimated_budget
              : null;

            const actual_cost = currentPhase ? currentPhase?.total_actual_budget : 0;
            const estimated_cost = currentPhase ? currentPhase?.total_estimated_budget : 0

            return {
              id: item?.id,
              currentPhase: phases.length > 0 ? `R${currentPhaseIndex}` : '',
              prev_cost: previous_total_cost ?? 0,
              curr_cost: current_total_cost ?? 0,
              actual_cost,
              estimated_cost,
              ...item?.attributes,
            };
          })
        );


        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.entityNames?.singular}`,
          description: `Remove ${config?.entityNames?.singular} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    let filters = [];

    if (selectedProject !== "") {
      filters.push({
        project: selectedProject,
      });
    }

    fetchData({
      $and: filters,
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
    setSearched(false);
  };

  const exportToExcel = async (data_array) => {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    // Create a new worksheet
    const worksheet = workbook.addWorksheet('Budget');

    // Define the columns for sub-milestone details
    worksheet.columns = [
      // { header: 'Sr No.', key: 'sr_no', width: 10 },
      // { header: 'Milestone', key: 'milestone', width: 15 },
      { header: 'Sub Milestone', key: 'sub_milestone', width: 25 },
      { header: 'Estimated Budget', key: 'estimated_budget', width: 20 },
      { header: 'Actual Budget', key: 'actual_budget', width: 20 },
      { header: 'Est. Labour Cost', key: 'estimated_labour_cost', width: 20 },
      { header: 'Est. Material Cost', key: 'estimated_material_cost', width: 20 },
      { header: 'Act. Labour Cost', key: 'actual_labour_cost', width: 20 },
      { header: 'Act. Material Cost', key: 'actual_material_cost', width: 20 },
      { header: 'Vendor', key: 'vendor', width: 40 },
      { header: 'Carpet Area', key: 'carpet_area', width: 14 },
      { header: 'Carpet Area Cost', key: 'carpet_area_cost', width: 18 },
      { header: 'Allocated Budget', key: 'allocated_budget', width: 18 },
      { header: 'Phase Total Est. Budget', key: 'phase_estimated_budget', width: 20 },
      { header: 'Phase Total Act. Budget', key: 'phase_actual_budget', width: 20 },
    ];

    // This variable will track the row count for "Sr No."
    let global_sr_no = 1;

    // Opinionated log to check raw data
    console.log('------------------DATA ARRAY------------------');
    console.log(data_array);

    // Iterate over each data item in the array
    data_array?.forEach((item, index) => {
      // Access project info if needed
      const project_attrs = item?.project?.data?.attributes ?? {};

      // Access array of phases
      const phases_array = item?.phase?.data ?? [];

      // For each phase, we have to gather sub-milestones
      phases_array.forEach((phase_obj, index) => {
        const phase_attrs = phase_obj?.attributes ?? {};

        // Retrieve phase-level fields
        const carpet_area = phase_attrs?.carpet_area ?? '';
        const carpet_area_cost = phase_attrs?.carpet_area_cost ?? '';
        const allocated_budget = phase_attrs?.allocated_budget ?? '';
        const phase_estimated_budget = phase_attrs?.total_estimated_budget ?? '';
        const phase_actual_budget = phase_attrs?.total_actual_budget ?? '';


        // 1) Create a single row with these phase-level fields (once per phase)
        //    We also bump sr_no for it
        const phase_row_data = {
          // sr_no: global_sr_no,
          milestone: '',            // blank, because we do NOT want to repeat this
          sub_milestone: '',        // blank
          estimated_budget: '',
          actual_budget: '',
          estimated_labour_cost: '',
          estimated_material_cost: '',
          actual_labour_cost: '',
          actual_material_cost: '',
          vendor: '',
          carpet_area: carpet_area,
          carpet_area_cost: carpet_area_cost,
          allocated_budget: allocated_budget,
          phase_estimated_budget: phase_estimated_budget,
          phase_actual_budget: phase_actual_budget,
        };

        // Insert the "phase row" into the sheet
        const phase_row = worksheet.addRow(phase_row_data);

        // (Optionally) style the phase row, e.g. with a background color:
        phase_row.eachCell((cell) => {
          cell.style = {
            font: { bold: true },  // make phase row stand out
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFE699' }, // light yellow
            },
          };
        });

        // Increment global_sr_no
        global_sr_no++;


        const sub_milestone_obj = phase_attrs?.sub_milestone ?? {};

        // sub_milestone_obj is an object with keys like "14", "15", etc.
        // Example:
        // {
        //   "14": [ { ...subMilestoneData... }, {...} ],
        //   "15": [ { ... }, ... ],
        //   ...
        // }

        const phase_heading = `${project_attrs?.name} ( R${index} )`;
        const phase_heading_row = worksheet.addRow([phase_heading]);

        // Merge cells from column 1 to the last column
        worksheet.mergeCells(
          phase_heading_row.number,
          1,
          phase_heading_row.number,
          worksheet.columns.length
        );

        // Style the single cell in that merged row (we reference cell 1)
        phase_heading_row.getCell(1).style = {
          font: { bold: true, size: 16 },
          alignment: { horizontal: 'start' },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            // Light Yellow fill (ARGB: alpha = FF, R=E6, G=99, B=some hex)
            fgColor: { argb: 'FFE699' },
          },
        };

        // **Add a blank row** right after the phase heading
        worksheet.addRow([]);

        // We can iterate over each milestone key in sub_milestone_obj
        for (const milestone_key in sub_milestone_obj) {
          if (Object.hasOwn(sub_milestone_obj, milestone_key)) {
            // We can do an optional "section" row here if you want big headings.
            // For example, you might do something like:
            // if (milestone_key === '14') => "1.00 CIVIL WORK"
            // if (milestone_key === '15') => "2.00 INTERIOR WORK"
            // Or you can just skip this step if you want everything continuous.

            // Opinionated: If you want to add a big bold heading for each milestone:
            const milestone_heading = `Milestone ${milestone_key}`;
            const heading_row = worksheet.addRow([milestone_heading]);
            // Merge cells across all columns for the heading
            heading_row.getCell(1).style = {
              font: { bold: true, size: 12 },
              alignment: { horizontal: 'start' },
              fill: {
                type: 'pattern',
                pattern: 'solid',
                // Light Gray fill (ARGB: FFCCCCCC is a mild gray)
                fgColor: { argb: 'FFCCCCCC' },
              },
            };
            // Merge from column A to column I (since we have 9 columns defined)
            worksheet.mergeCells(
              heading_row.number,
              1,
              heading_row.number,
              worksheet.columns.length
            );


            // Now get the array of sub-milestones for that milestone
            const sub_milestones_array = sub_milestone_obj[milestone_key];
            sub_milestones_array.forEach((sm) => {

              // Add a row with all relevant data
              worksheet.addRow({
                // sr_no: global_sr_no,
                milestone: sm?.milestone ?? milestone_key, // or whichever you prefer
                sub_milestone: sm?.sub_milestone ?? '',
                estimated_budget: sm?.estimated_budget ?? '',
                actual_budget: sm?.actual_budget ?? '',
                estimated_labour_cost: sm?.estimated_labour_cost ?? '',
                estimated_material_cost: sm?.estimated_material_cost ?? '',
                actual_labour_cost: sm?.actual_labour_cost ?? '',
                actual_material_cost: sm?.actual_material_cost ?? '',
                vendor: '',
                carpet_area: '',
                carpet_area_cost: '',
                allocated_budget: '',
                phase_estimated_budget: '',
                phase_actual_budget: '',
              });

              // Increment the global row counter
              global_sr_no++;
            });

            worksheet.addRow([]);

          }
        }
      });
    });

    // Finally, write the workbook to a buffer
    const buffer = await workbook.xlsx.writeBuffer();
    // Convert buffer to a blob and save
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, 'Budget.xlsx');
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    fetchData(filters);
    fetchProjects()
  }, []);

  console.log('Tracker', data)

  return (
    <ReportDataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns
          ?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          }))

      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: () => navigate(`list/add`),
        onRefresh: refreshData,
      }}
      custom_excel_report={() => exportToExcel(data)}
      Toolbar={
        <div className="toolbar">
          <Select
            value={selectedProject}
            onChange={setSelectedProject}
            options={projects}
            style={{ width: "100%", marginTop: '20px' }}
            placeholder="Select Project"
          />

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
      buttons={
        <div>
          {''}
        </div>
      }
    />
  );
};

export default BalanceToPay;
