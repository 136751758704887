import React from "react";
import { Route, Navigate } from "react-router-dom";
import NotFound from "../../../pages/NotFound";


import * as BudgetTrackers from "./budget-tracker.route";
import BudgetTracker from "..";
import BudgetReports from "../pages/Reports/Reports";
import TrackerReport from "../pages/Reports/List";
import VendorLedgerReport from "../pages/Reports/VendorLedger";
import BalanceToPay from "../pages/Reports/BalanceToPay";

export const register = (props) => {
  const basePath = "/budget-tracker";

  return (
    <Route path={basePath} element={<BudgetTracker />}>

      {BudgetTrackers.register()}

      <Route path={`${basePath}/report`} element={<BudgetReports />} />
      <Route path={`${basePath}/report/activity-budget`} element={<TrackerReport />} />
      <Route path={`${basePath}/report/vendors-ledger`} element={<VendorLedgerReport />} />
      <Route path={`${basePath}/report/balance-to-pay`} element={<BalanceToPay />} />

      <Route path="*" element={<NotFound returnPath={basePath} />} />
    </Route>
  );
};
