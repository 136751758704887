import React, { useState, useEffect, useCallback } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { axios } from "../../../../../App";
import qs from "qs";
import _ from "lodash";
import { Empty, Input } from "antd";


const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({});
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
          approved_by: data,
        })),
    },

    {
      slug: "vendors",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor: data,
        })),
    },

    {
      slug: "sub-contractors",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractor: data,
        })),
    },

    {
      slug: "agencies",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          agency: data,
        })),
    },

    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
        })),
    },
  ];

  const [selectedVendor, setselectedVendor] = useState(null);
  const [selectedSubContractor, setselectedSubContractor] = useState(null);
  const [selectedAgency, setselectedAgency] = useState(null);
  const [selectedProject, setselectedProject] = useState(null);
  const [allPayments, setAllPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const [materialItemNotes, setMaterialItemNotes] = useState(
    selectedPayment?.material_item_notes || []
  );

  // If you need to sync with parent changes
  useEffect(() => {
    setMaterialItemNotes(selectedPayment?.material_item_notes || []);
  }, [selectedPayment]);

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
  }, []);

  const fetchVendorPO = async (project) => {
    try {

      const queryObject = {
        populate: "*",
        sort: ["estimated_amount:desc"],
        filters: {
          vendor: selectedVendor,
          project: project,
          status: "Approved"
        },
      };



      const response = await axios.get(
        `vendor-quotations?${qs.stringify(queryObject)}`
      );

      const queryMaterialObject = {
        populate: "*",
        filters: {
          project: project,
          purchase_order: response?.data?.data?.[0]?.id
        },
      };

      const materialResponse = await axios.get(
        `vendor-material-receivables?${qs.stringify(queryMaterialObject)}`
      );


      console.log('materialResponse', materialResponse)

      if (response?.data?.data?.length > 0) {

        const paymentSchedule = response?.data?.data.flatMap(item =>
          item.attributes.payment_schedules.map(schedule => ({
            label: `${schedule.name} (${item.attributes.heading})`,
            value: schedule?.id
          }))
        );


        const payments = response?.data?.data.flatMap(item =>
          item.attributes.payment_schedules.map(schedule => ({
            ...schedule,
            material_item_notes: item?.attributes?.material_item_notes?.filter(item => item.material_group == schedule.material_group?.data?.id),
            material_items: item?.attributes?.material_items?.filter(item => item?.raw_data?.material_group == schedule?.material_group?.data?.id),
            received_quantity: materialResponse?.data?.data?.[0]?.attributes?.received_quantity
          }))
        );
        setValues((prev) => ({
          ...prev,
          payment: paymentSchedule,
        }))

        setAllPayments(payments)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubContractorWO = async (project) => {
    try {

      const queryObject = {
        populate: "*",
        sort: ["estimated_amount:desc"],
        filters: {
          sub_contractor: selectedSubContractor,
          project: project,
          status: "Approved"
        },
      };


      const response = await axios.get(
        `sub-contractor-quotations?${qs.stringify(queryObject)}`
      );


      if (response?.data?.data?.length > 0) {
        console.log('Response', response)
        const paymentSchedule = response?.data?.data.flatMap(item =>
          item.attributes.payment_schedules.map(schedule => ({
            label: `${schedule.name} (${item.attributes.heading})`,
            value: schedule?.id
          }))
        );


        const payments = response?.data?.data.flatMap(item =>
          item.attributes.payment_schedules.map(schedule => ({
            ...schedule,
            work_item_notes: item?.attributes?.work_item_notes
          }))
        );
        setValues((prev) => ({
          ...prev,
          payment: paymentSchedule,
        }))

        setAllPayments(payments)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateMaterialAmount = (paymentSchedule, materialItemNotes, includeTaxes = true) => {

    // Find the corresponding materialItemNote based on the material_group
    const materialItemNote = materialItemNotes?.find(
      (item) => item?.material_group === paymentSchedule?.material_group?.data?.id
    );

    console.log('calculating material amount', paymentSchedule, materialItemNote);
    // If no corresponding materialItemNote is found, return 0
    if (!materialItemNote) {
      return 0;
    }

    // Calculate the base amount based on percentage
    const baseAmount =
      (Number(paymentSchedule.amount_percentage) / 100) *
      (materialItemNote.amount * (materialItemNote?.received_quantity ?? paymentSchedule?.received_quantity));

    if (!includeTaxes) {
      // If taxes are not included, return the base amount
      return baseAmount;
    }

    // Calculate tax amounts (assuming cqst and sqst are percentages)
    const cgstTax = (materialItemNote.cqst / 100) * baseAmount;
    const sgstTax = (materialItemNote.sqst / 100) * baseAmount;
    const igstTax = isNaN(materialItemNote.igst) ? 0 : (materialItemNote.igst / 100) * baseAmount;



    // Calculate the total amount, including taxes
    const totalAmount = baseAmount + cgstTax + sgstTax + igstTax;

    return totalAmount;
  };


  const calculateWorkAmount = (paymentSchedule, workItemNotes) => {
    // Find the corresponding materialItemNote based on the material_group
    const workItemNote = workItemNotes?.find(
      (item) => item?.raw_data?.work_group === paymentSchedule?.work_group?.data?.id
    );


    // If no corresponding workItemNote is found, return 0
    if (!workItemNote) {
      return 0;
    }

    // Calculate the base amount based on percentage
    const baseAmount =
      (Number(paymentSchedule.amount_percentage) / 100) *
      (workItemNote.amount * workItemNote.quantity);

    // Calculate tax amounts (assuming cqst and sqst are percentages)
    const cqstTax = (workItemNote.cqst / 100) * baseAmount;
    const sqstTax = (workItemNote.sqst / 100) * baseAmount;

    // Calculate the total amount, including taxes
    const totalAmount = baseAmount + cqstTax + sqstTax;

    return totalAmount;
  };


  // Handler for labour cost changes
  const handleLabourCostChange = useCallback(
    (value, index) => {
      const updatedNotes = [...materialItemNotes];
      updatedNotes[index] = {
        ...updatedNotes[index],
        estimated_labour_cost: value,
        amount: Number(updatedNotes[index].estimated_material_cost) + Number(value)
      };
      setMaterialItemNotes(updatedNotes);
    },
    [materialItemNotes]
  );

  // Handler for material cost changes
  const handleMaterialCostChange = useCallback(
    (value, index) => {
      const updatedNotes = [...materialItemNotes];
      updatedNotes[index] = {
        ...updatedNotes[index],
        estimated_material_cost: value,
        amount: Number(updatedNotes[index].estimated_labour_cost) + Number(value)
      };
      setMaterialItemNotes(updatedNotes);
    },
    [materialItemNotes]
  );

  const handle_quantity_change = useCallback(
    (value, index) => {
      //   setSelectedPayment((prev) => ({
      //     ...prev,
      //     received_quantity: value
      //   }))
      // },
      const updated_notes = [...materialItemNotes];
      updated_notes[index] = {
        ...updated_notes[index],
        received_quantity: value
      };
      setMaterialItemNotes(updated_notes);
    },
    [materialItemNotes]
  );

  /**
   * Handler for CGST (cqst) changes.
   * We'll store the new CGST value in the note, and recalc in the table as needed.
   */
  const handle_cqst_change = useCallback(
    (value, index) => {
      const updated_notes = [...materialItemNotes];
      updated_notes[index] = {
        ...updated_notes[index],
        cqst: Number(value),
      };
      setMaterialItemNotes(updated_notes);
    },
    [materialItemNotes]
  );

  /**
   * Handler for SGST (sqst) changes.
   */
  const handle_sqst_change = useCallback(
    (value, index) => {
      const updated_notes = [...materialItemNotes];
      updated_notes[index] = {
        ...updated_notes[index],
        sqst: Number(value),
      };
      setMaterialItemNotes(updated_notes);
    },
    [materialItemNotes]
  );

  /**
   * Handler for IGST (igst) changes.
   */
  const handle_igst_change = useCallback(
    (value, index) => {
      const updated_notes = [...materialItemNotes];
      updated_notes[index] = {
        ...updated_notes[index],
        igst: Number(value),
      };
      setMaterialItemNotes(updated_notes);
    },
    [materialItemNotes]
  );

  console.log('Recieved Invoice', values, materialItemNotes)

  return (
    <AddForm
      config={config}
      values={values}
      data={{ reconciliation: materialItemNotes, payment_schedule: selectedPayment }}
      onFieldChange={{
        vendor: (antForm) => {
          const vendorId = antForm.getFieldValue("vendor");

          if (vendorId) {
            setselectedVendor(vendorId);
            setselectedAgency(null);
            antForm.setFieldValue("agency", null);

            setselectedSubContractor(null);
            antForm.setFieldValue("sub_contractor", null);
          }
        },
        sub_contractor: (antForm) => {
          const subContractorId = antForm.getFieldValue("sub_contractor");

          if (subContractorId) {
            setselectedSubContractor(subContractorId);
            setselectedAgency(null);
            antForm.setFieldValue("agency", null);
            setselectedVendor(null);
            antForm.setFieldValue("vendor", null);
          }
        },
        agency: (antForm) => {
          const agencyId = antForm.getFieldValue("agency");

          if (agencyId) {
            setselectedAgency(agencyId);
            setselectedVendor(null);
            antForm.setFieldValue("vendor", null);
            setselectedSubContractor(null);
            antForm.setFieldValue("sub_contractor", null);
          }
        },
        project: (antForm) => {
          const projectId = antForm.getFieldValue("project");

          if (projectId) {
            setselectedProject(projectId);
            setValues((prev) => ({
              ...prev,
              payment: [],
            }))

            if (selectedVendor) {
              fetchVendorPO(projectId);
            } else if (selectedSubContractor) {
              fetchSubContractorWO(projectId);
            }
          }
        },
        payment: (antForm) => {
          const paymentId = antForm.getFieldValue("payment");
          const selected_payment = allPayments.find(payment => payment?.id == paymentId);
          const payable_amount = selected_payment?.material_item_notes ? calculateMaterialAmount(selected_payment, selected_payment.material_item_notes, true) : calculateWorkAmount(selected_payment, selected_payment.work_item_notes);

          if (paymentId) {
            antForm.setFieldValue("payable_amount", payable_amount);

            setSelectedPayment({
              ...selected_payment,
              payable_amount: payable_amount
            });

          }
        },
      }}
    >
      <div className="public-form-body">
        {selectedPayment && (
          <div className="public-form-section">
            {/* <h2 className="public-form-section-title">Material Items</h2> */}

            <table className="public-form-table">
              <thead>
                <tr>
                  <th>No.</th>
                  {/* <th>Material Group</th>
                    <th>Material Item</th> */}
                  {/* <th>Material Unit</th> */}
                  <th style={{ width: "20%" }}>Item Description</th>
                  <th>Quantity</th>
                  <th style={{ width: "10%" }}>Labour Cost(₹)</th>
                  <th style={{ width: "10%" }}>Material Cost(₹)</th>
                  <th style={{ width: "10%" }}>Rate(₹)</th>
                  <th style={{ width: "10%" }}>CGST (%)</th>
                  <th style={{ width: "10%" }}>SGST (%)</th>
                  <th style={{ width: "10%" }}>IGST (%)</th>
                  <th style={{ width: "10%" }}>Amount (%)</th>
                  <th style={{ width: "10%" }}>Taxable Amount(₹)</th>
                  <th style={{ width: "10%" }}>Total with Tax(₹)</th>
                </tr>
              </thead>


              <tbody>
                {selectedPayment?.material_items?.length > 0 &&
                  selectedPayment?.material_items?.map((materialItem, index) => {

                    return (
                      <tr key={materialItem?.id}>
                        <td className="text-center text-bold">{index + 1}</td>
                        {/* <td>{materialItem?.material_group || "N/A"}</td>
                          <td>{materialItem?.material_item || "N/A"}</td> */}
                        <td dangerouslySetInnerHTML={{ __html: selectedPayment?.description }}></td>
                        <td>{selectedPayment?.received_quantity || 0}</td>
                        <td style={{ fontWeight: "bold" }}>
                          {selectedPayment?.material_item_notes?.[index]?.estimated_labour_cost || 0}
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                          {selectedPayment?.material_item_notes?.[index]?.estimated_material_cost || 0}
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                          {selectedPayment?.material_item_notes?.[index]?.amount || 0}
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                          {selectedPayment?.material_item_notes?.[index]?.cqst || 0}
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                          {selectedPayment?.material_item_notes?.[index]?.sqst || 0}
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                          {selectedPayment?.material_item_notes?.[index]?.igst || 0}
                        </td>
                        <td>{selectedPayment?.amount_percentage}</td>
                        <td>{calculateMaterialAmount(selectedPayment, selectedPayment?.material_item_notes, false)}</td>
                        <td>{calculateMaterialAmount(selectedPayment, selectedPayment?.material_item_notes, true)}</td>
                      </tr>
                    );
                  })}


                {(!selectedPayment?.material_items ||
                  selectedPayment?.material_items?.length === 0) && (
                    <tr>
                      <td colSpan={8} style={{ padding: 24 }}>
                        <Empty />
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        )}


        {selectedPayment && (
          <div className="public-form-section">
            <table className="public-form-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th style={{ width: "20%" }}>Item Description</th>
                  <th>Quantity</th>
                  <th style={{ width: "10%" }}>Labour Cost(₹)</th>
                  <th style={{ width: "10%" }}>Material Cost(₹)</th>
                  <th style={{ width: "10%" }}>Rate(₹)</th>
                  <th style={{ width: "10%" }}>CGST (%)</th>
                  <th style={{ width: "10%" }}>SGST (%)</th>
                  <th style={{ width: "10%" }}>IGST (%)</th>
                  <th style={{ width: "10%" }}>Amount (%)</th>
                  <th style={{ width: "10%" }}>Taxable Amount(₹)</th>
                  <th style={{ width: "10%" }}>Total with Tax(₹)</th>
                </tr>
              </thead>

              <tbody>
                {selectedPayment?.material_items?.length > 0 ? (
                  selectedPayment?.material_items?.map((materialItem, index) => {
                    const note = materialItemNotes[index];
                    // For simplicity, we’ll re-use note.amount for "rate(₹)" 
                    // but you could similarly update it from an Input if needed.
                    const baseAmountWithoutTax = calculateMaterialAmount(
                      selectedPayment,
                      [note],

                      false
                    );
                    const totalAmountWithTax = calculateMaterialAmount(
                      selectedPayment,
                      [note],
                      true
                    );

                    return (
                      <tr key={materialItem?.id}>
                        <td className="text-center text-bold">{index + 1}</td>
                        <td dangerouslySetInnerHTML={{ __html: selectedPayment?.description }}></td>
                        <td style={{ fontWeight: "bold" }}>
                          <Input
                            value={note?.received_quantity || selectedPayment?.received_quantity}
                            onChange={(e) =>
                              handle_quantity_change(e.target.value, index)
                            }
                          />
                        </td>

                        {/* Labour Cost Input */}
                        <td style={{ fontWeight: "bold" }}>
                          <Input
                            value={note?.estimated_labour_cost || ""}
                            onChange={(e) =>
                              handleLabourCostChange(e.target.value, index)
                            }
                          />
                        </td>

                        {/* Material Cost Input */}
                        <td style={{ fontWeight: "bold" }}>
                          <Input
                            value={note?.estimated_material_cost || ""}
                            onChange={(e) =>
                              handleMaterialCostChange(e.target.value, index)
                            }
                          />
                        </td>

                        <td style={{ fontWeight: "bold" }}>
                          {note?.amount || 0}
                        </td>
                        {/* CGST as an input field */}
                        <td style={{ fontWeight: "bold" }}>
                          <Input
                            value={note?.cqst || 0}
                            onChange={(e) =>
                              handle_cqst_change(e.target.value, index)
                            }
                          />
                        </td>

                        {/* SGST as an input field */}
                        <td style={{ fontWeight: "bold" }}>
                          <Input
                            value={note?.sqst || 0}
                            onChange={(e) =>
                              handle_sqst_change(e.target.value, index)
                            }
                          />
                        </td>

                        {/* IGST as an input field */}
                        <td style={{ fontWeight: "bold" }}>
                          <Input
                            value={note?.igst || 0}
                            onChange={(e) =>
                              handle_igst_change(e.target.value, index)
                            }
                          />
                        </td>

                        <td style={{ fontWeight: "bold" }}>
                          {selectedPayment?.amount_percentage || 0}
                        </td>

                        {/* Taxable Amount (without tax) */}
                        <td>{baseAmountWithoutTax.toFixed(2)}</td>

                        {/* Total with Tax */}
                        <td>{totalAmountWithTax.toFixed(2)}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={12} style={{ padding: 24 }}>
                      <Empty />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </AddForm>
  );
};

export default Page;
