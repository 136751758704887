import { axios } from "../../../../../App";
import config from "../config";
import AddMaterial from "../AddMaterial";
import EditMaterial from "../EditMaterial";
import AddPaymentSchedule from "../AddPaymentSchedule";
import EditPaymentSchedule from "../EditPaymentSchedule";
import AddTerm from "../AddTerm";
import EditTerm from "../EditTerm";
import AddExistingMaterialItem from "../AddExistingMaterialItem";
import AddExistingTerm from "../AddExistingTerm";
import AddExistingPaymentSchedule from "../AddExistingPaymentSchedule";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Heading",
      name: "heading",
      required: true,
      placeholder: "Enter heading",
      rules: [
        {
          required: true,
          message: "Heading is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "date",
    //   label: "Scheduled Date",
    //   name: "scheduled_date",
    //   placeholder: "Select scheduled date",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Scheduled date is required!",
    //     },
    //   ],
    // },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Material Group",
    //   name: "material_group",
    //   placeholder: "Select material group",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Material group is required!",
    //     },
    //   ],
    //   mode: "multiple",
    // },
    {
      span: 12,
      component: "select",
      label: "Delivery Address",
      name: "delivery_address",
      placeholder: "Select delivery address",
      required: true,
      rules: [
        {
          required: true,
          message: "Delivery address is required!",
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Budget",
      name: "budget",
      required: true,
      placeholder: "Enter budget",
      rules: [
        {
          required: true,
          message: "Budget is required!",
        },
      ],
      min: 0,
    },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Estimated Amount",
    //   name: "estimated_amount",
    //   required: true,
    //   placeholder: "Enter estimated amount",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Estimated amount is required!",
    //     },
    //   ],
    //   min: 0,
    // },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Terms & Conditions",
    //   name: "vendor_term",
    //   placeholder: "Select terms & conditions",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Terms & conditions is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "date",
      label: "Expected Delivery Date",
      name: "expected_delivery_date",
      placeholder: "Select expected delivery date",
      required: true,
      rules: [
        {
          required: true,
          message: "Expected delivery date is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "date",
    //   label: "Delivery Date",
    //   name: "delivery_date",
    //   placeholder: "Select delivery date",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Delivery date is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "select",
      label: "Urgency Level",
      name: "urgency_level",
      placeholder: "Select urgency level",
      required: true,
      rules: [
        {
          required: true,
          message: "Urgency level is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Approved by is required!",
        },
      ],
    },
    {
      span: 24,
      component: "select",
      label: "Vendors",
      name: "vendors",
      placeholder: "Select vendors",
      required: true,
      rules: [
        {
          required: true,
          message: "Vendors is required!",
        },
      ],
      mode: "multiple",
    },
    {
      span: 12,
      component: "select",
      label: "Boq",
      name: "vendor_boq",
      placeholder: "Select Boq",

    },
    {
      span: 24,
      component: "editor",
      label: "Material Specifications",
      name: "remarks",
      placeholder: "Enter material specifications",
      rows: 5,
    },
    {
      span: 24,
      component: "editor",
      label: "Approval Remarks",
      name: "approval_remarks",
      placeholder: "Enter approval remarks",
      rows: 5,
    },
    {
      span: 24,
      component: "repeatable",
      label: "Material Items",
      name: "material_items",
      required: true,
      rules: [
        {
          required: true,
          message: "Material Items is required!",
        },
      ],
      list: [
        {
          name: "Material Group",
          dataIndex: "material_group",
        },
        {
          name: "Material Item",
          dataIndex: "material_item",
        },
        // {
        //   name: "Material Unit",
        //   dataIndex: "material_unit",
        // },
        {
          name: "Quantity",
          dataIndex: "quantity",
        },
      ],
      modalComponents: {
        add: (params) => {
          return <AddMaterial {...params} />;
        },
        edit: (params) => {
          return <EditMaterial {...params} />;
        },
        list: (params) => {
          return <AddExistingMaterialItem {...params} />;
        },
      },
    },
    {
      span: 24,
      component: "repeatable",
      label: "Payment Schedules",
      name: "payment_schedules",
      hide_actions: true,
      required: true,
      rules: [
        {
          required: true,
          message: "Payment Schedules is required!",
        },
      ],
      list: [
        {
          name: "Name",
          dataIndex: "name",
        },
        {
          name: "Description",
          dataIndex: "description",
          render: (text) => (
            <p dangerouslySetInnerHTML={{ __html: text || "N/A" }} />
          ),
        },
        {
          name: "Amount %",
          dataIndex: "amount_percentage",
        },
        {
          name: "Date",
          dataIndex: "date",
        },
      ],
      modalComponents: {
        // add: (params) => {
        //   return <AddPaymentSchedule {...params} />;
        // },
        edit: (params) => {
          return <EditPaymentSchedule {...params} />;
        },
        // list: (params) => {
        //   return <AddExistingPaymentSchedule {...params} />;
        // },
      },
    },
    {
      span: 24,
      component: "repeatable",
      label: "Terms & Conditions",
      name: "terms",
      required: true,
      rules: [
        {
          required: true,
          message: "Terms & Conditions is required!",
        },
      ],
      list: [
        {
          name: "Heading",
          dataIndex: "heading",
        },
        {
          name: "Material Group",
          dataIndex: "material_group",
        },
        {
          name: "Material Item",
          dataIndex: "material_item",
        },
        // {
        //   name: "Material Unit",
        //   dataIndex: "material_unit",
        // },
        // {
        //   name: "Remarks",
        //   dataIndex: "remarks",
        //   render: (text) => (
        //     <p dangerouslySetInnerHTML={{ __html: text || "N/A" }} />
        //   ),
        // },
      ],
      modalComponents: {
        add: (params) => {
          return <AddTerm {...params} />;
        },
        edit: (params) => {
          return <EditTerm {...params} />;
        },
        list: (params) => {
          return <AddExistingTerm {...params} />;
        },
      },
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: {
              ...values,
              scheduled_date: values?.scheduled_date?.add(1, "days"),
              material_items: data?.material_items,
              payment_schedules: data?.payment_schedules,
              terms: data?.terms,
            },
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});