import config from "../config";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => ` ${record?.name}`,
      },
    },
    {
      title: "Amount Payable",
      dataIndex: "amount_payable",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.amount_payable - b?.amount_payable,
    },
    {
      title: "Approved By",
      dataIndex: "approved_by",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => `${record?.first_name} ${record?.last_name}`,
      },
    },
  ],
  ...config,
};
