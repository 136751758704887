import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { v4 } from "uuid";
import moment from "moment";
import { axios } from "../../../../../App";
import qs from "qs";
import { DatePicker, Empty, InputNumber } from "antd";


const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    urgency_level: ["Low", "Medium", "High"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const [workItems, setWorkItems] = useState([]);
  const [paymentSchedules, setPaymentSchedules] = useState([]);
  const [terms, setTerms] = useState([]);
  const contentTypes = [
    {
      slug: "work-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_group: data,
        })),
    },
    {
      slug: "work-items",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_item: data,
        })),
    },
    {
      slug: "work-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_unit: data,
        })),
    },
    {
      slug: "sub-contractors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractors: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
        })),
    },
    {
      slug: "sub-contractor-terms",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: item?.heading,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractor_terms: data,
        })),
    },
    {
      slug: "locations",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.location_type?.data?.attributes?.name})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          delivery_address: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          //  approved_by: data,
        })),
    },
    {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          approved_by: data,
        })),
    },
    {
      slug: "sub-contractor-boqs",
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractor_boq: data,
        })),
    },
  ];

  // WORK ITEMS

  const transformWorkItem = (rawData) => {
    return {
      ...rawData,
      work_group: values?.work_group?.find(
        (item) => item?.value === rawData?.work_group
      )?.label,
      work_item: values?.work_item?.find(
        (item) => item?.value === rawData?.work_item
      )?.label,
      work_unit: values?.work_unit?.find(
        (item) => item?.value === rawData?.work_unit
      )?.label,
      raw_data: rawData,
    };
  };

  const onAddWorkItem = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformWorkItem(data),
    };

    setWorkItems((prev) => prev?.concat(newData));
  };

  const onAddExistingWorkItem = (data) => {
    const raw = {
      work_group: data?.work_group?.data?.id,
      work_item: data?.work_item?.data?.id,
      work_unit: data?.work_unit?.data?.id,
      quantity: data?.quantity,
      // amount: data?.amount,
    };

    const newData = {
      id: v4(),
      ...raw,
      ...transformWorkItem(raw),
    };

    setWorkItems((prev) => prev?.concat(newData));
  };

  const onEditWorkItem = (id, data) => {
    let newItems = workItems?.map((record) => {
      if (record?.id === id) {
        record = {
          ...record,
          ...transformWorkItem(data),
        };
      }

      return record;
    });

    setWorkItems(newItems);
  };

  const onDeleteWorkItem = (id) => {
    setWorkItems((prev) => prev?.filter((item) => item?.id !== id));
  };

  // PAYMENT SCHEDULES

  const transformPaymentSchedule = (rawData) => {
    return {
      ...rawData,
      scheduled_date: moment(rawData?.scheduled_date)?.format("DD-MM-YYYY"),
      raw_data: rawData,
    };
  };

  const onAddPaymentSchedule = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setPaymentSchedules((prev) => prev?.concat(newData));
  };

  const onAddExistingPaymentSchedule = (data) => {
    const raw = {
      scheduled_date: data?.scheduled_date,
      amount: data?.amount,
      description: data?.description,
    };

    const newData = {
      id: v4(),
      ...raw,
      ...transformPaymentSchedule(raw),
    };

    setPaymentSchedules((prev) => prev?.concat(newData));
  };

  const onEditPaymentSchedule = (id, data) => {
    let newItems = paymentSchedules?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setPaymentSchedules(newItems);
  };

  const onDeletePaymentSchedule = (id) => {
    setPaymentSchedules((prev) => prev?.filter((item) => item?.id !== id));
  };

  // TERMS

  const transformTerm = (rawData) => {
    return {
      ...rawData,
      work_group: values?.work_group?.find(
        (item) => item?.value === rawData?.work_group
      )?.label,
      work_item: values?.work_item?.find(
        (item) => item?.value === rawData?.work_item
      )?.label,
      work_unit: values?.work_unit?.find(
        (item) => item?.value === rawData?.work_unit
      )?.label,
      raw_data: rawData,
    };
  };

  const onAddTerm = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformTerm(data),
    };

    setTerms((prev) => prev?.concat(newData));
  };

  const onAddExistingTerm = (data) => {
    const raw = {
      heading: data?.heading,
      work_group: data?.work_group?.data?.id,
      work_item: data?.work_item?.data?.id,
      work_unit: data?.work_unit?.data?.id,
      remarks: data?.remarks,
    };

    const newData = {
      id: v4(),
      ...raw,
      ...transformTerm(raw),
    };

    setTerms((prev) => prev?.concat(newData));
  };

  const onEditTerm = (id, data) => {
    let newItems = terms?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformTerm(data),
        };
      }

      return record;
    });

    setTerms(newItems);
  };

  const onDeleteTerm = (id) => {
    setTerms((prev) => prev?.filter((item) => item?.id !== id));
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSubcontractorSchedulesData = async (params = null) => {
    try {

      const queryObject = {
        populate: "*",
        // sort: ["heading:asc"],
      };

      // if (!_.isEmpty(params)) {
      //   queryObject["filters"] = params;
      // }

      const response = await axios.get(
        `sub-contractor-payment-schedules?${qs.stringify(queryObject)}`
      );

      console.log('response', response);

      if (response?.data) {
        // Create a set of work_group IDs from materialItems
        const materialGroupIds = new Set(workItems?.map(item => item.raw_data.work_group));

        // Filter the data based on work_group IDs
        const filtered_data = response?.data?.data?.filter(
          (s) => materialGroupIds?.has(s?.attributes?.work_group?.data?.id)
        );

        console.log('Material IDS', materialGroupIds, response?.data?.data, filtered_data)

        if (filtered_data) {
          // Extract all payment schedules from the filtered data
          const payments = filtered_data?.flatMap(payment =>
            payment?.attributes?.payment_schedule?.map(schedule => ({
              ...schedule,
              work_group: payment.attributes.work_group
            }))
          );

          // Do something with the payments, e.g., set state
          setPaymentSchedules(payments);
        }

      }

    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle date change
  const handleDateChange = (date, dateString, id) => {
    setPaymentSchedules((prevSchedules) =>
      prevSchedules.map((schedule) =>
        schedule.id === id ? { ...schedule, date: dateString } : schedule
      )
    );
  };

  useEffect(() => {
    fetchSubcontractorSchedulesData();

  }, [workItems])

  console.log('subcontractor ', paymentSchedules)

  return (
    <AddForm
      config={config}
      values={values}
      modals={{
        work_items: {
          data: workItems,
          methods: {
            add: onAddWorkItem,
            edit: onEditWorkItem,
            delete: onDeleteWorkItem,
            addExisting: onAddExistingWorkItem,
          },
        },
        // payment_schedules: {
        //   data: paymentSchedules,
        //   methods: {
        //     add: onAddPaymentSchedule,
        //     edit: onEditPaymentSchedule,
        //     delete: onDeletePaymentSchedule,
        //     addExisting: onAddExistingPaymentSchedule,
        //   },
        // },
        terms: {
          data: terms,
          methods: {
            add: onAddTerm,
            edit: onEditTerm,
            delete: onDeleteTerm,
            addExisting: onAddExistingTerm,
          },
        },
      }}
      data={{
        work_items: workItems,
        payment_schedules: paymentSchedules,
        terms,
      }}
      onFieldChange={{
        sub_contractor_boq: (antForm) => {
          const boqId = antForm.getFieldValue("sub_contractor_boq");
          const boq = values?.sub_contractor_boq?.find(data => data.value === boqId)


          if (boq) {
            setWorkItems(boq?.boq_item?.map(data => ({
              ...data,
              work_group: values?.work_group?.find(group => group.value === data.work_group)?.label,
              work_item: values?.work_item?.find(item => item.value === data.work_item)?.label,
              work_unit: values?.work_unit?.find(item => item.value === data.work_unit)?.label,
              raw_data: data,
            })))
          }
        },

        sub_contractor_terms: (antForm) => {
          const termId = antForm.getFieldValue("sub_contractor_terms");
          const term = values?.sub_contractor_terms?.filter(data => data.value === termId)

          if (term) {
            setTerms(term?.map(data => ({
              ...data,
              work_group: values?.work_group?.find(group => group.value === data.work_group?.data?.id)?.label,
              work_item: values?.work_item?.find(item => item.value === data.work_item?.data?.id)?.label,
              raw_data: data,
            })))
          }
        },
      }}
    >
      <div className="public-form-section">
        <h2 className="public-form-section-title">Payment Schedules</h2>

        <div style={{ overflowX: "auto" }}>
          <table className="public-form-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Material Group</th>
                <th>Description</th>
                <th>Amount %</th>
                <th style={{ width: '20%' }}>Date</th>
              </tr>
            </thead>

            <tbody>
              {paymentSchedules?.length > 0 &&
                paymentSchedules?.map((data, index) => {

                  return (
                    <tr key={data?.id}>
                      <td className="text-center text-bold">{index + 1}</td>
                      <td>{data?.name || "N/A"}</td>
                      <td>{data?.work_group?.data?.attributes?.name || "N/A"}</td>
                      <td>{data?.description}</td>
                      <td>{data?.amount_percentage}</td>
                      <td>
                        <DatePicker
                          onChange={(date, dateString) =>
                            handleDateChange(date, dateString, data?.id)
                          }
                          value={data?.date ? moment(data?.date, "YYYY-MM-DD") : null}
                        />
                      </td>
                    </tr>
                  );
                })}

              {paymentSchedules?.length === 0 && (
                <tr>
                  <td colSpan={11} style={{ padding: 24 }}>
                    <Empty />
                  </td>
                </tr>
              )}


            </tbody>
          </table>
        </div>
      </div>
    </AddForm>
  );
};

export default Page;
