import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Employee",
    //   name: "employee",
    //   placeholder: "Select employee",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Employee is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "select",
      label: "vendor",
      name: "vendor",
      placeholder: "Select vendor",

    },
    {
      span: 12,
      component: "select",
      label: "Sub Contractor",
      name: "sub_contractor",
      placeholder: "Select subcontractor",
    },
    {
      span: 12,
      component: "select",
      label: "Agency",
      name: "agency",
      placeholder: "Select agency",
    },

    {
      span: 12,
      component: "select",
      label: "Projects",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Projects is required!",
        },
      ],
    },


    {
      span: 12,
      component: "select",
      label: "Payment Schedule",
      name: "payment",
      placeholder: "Select payment",
      disabled: true,
      // required: true,
      // rules: [
      //   {
      //     required: true,
      //     message: "Payment Schedule is required!",
      //   },
      // ],
    },

    {
      span: 12,
      component: "number",
      label: "Amount Payable",
      name: "amount_payable",
      placeholder: "Payable Amount",
      disabled: true,

    },

    // {
    //   span: 12,
    //   component: "number",
    //   label: "Total Amount",
    //   name: "total_amount",
    //   required: true,
    //   placeholder: "Enter total amount",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Total amount is required!",
    //     },
    //   ],
    //   min: 0,
    // },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Total Tax",
    //   name: "total_tax",
    //   required: true,
    //   placeholder: "Enter total tax",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Total tax is required!",
    //     },
    //   ],
    //   min: 0,
    // },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Transportation",
    //   name: "transportation",
    //   required: true,
    //   placeholder: "Enter transportation",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Transportation is required!",
    //     },
    //   ],
    //   min: 0,
    // },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Transportation Tax",
    //   name: "transportation_tax",
    //   required: true,
    //   placeholder: "Enter transportation tax",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Transportation tax is required!",
    //     },
    //   ],
    //   min: 0,
    // },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "NET Amount",
    //   name: "net_amount",
    //   required: true,
    //   placeholder: "Enter net amount",
    //   rules: [
    //     {
    //       required: true,
    //       message: "NET amount is required!",
    //     },
    //   ],
    //   min: 0,
    // },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Discount",
    //   name: "discount",
    //   required: true,
    //   placeholder: "Enter discount",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Discount is required!",
    //     },
    //   ],
    //   min: 0,
    // },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Grand Total",
    //   name: "grand_total",
    //   required: true,
    //   placeholder: "Enter grand total",
    //   rules: [
    //     {
    //       required: true,
    //       message: "Grand total is required!",
    //     },
    //   ],
    //   min: 0,
    // },
    {
      span: 12,
      component: "uploader",
      label: "Attachment",
      name: "attachment",
      required: true,
      placeholder: "Upload attachment",
      rules: [
        {
          required: true,
          message: "Attachment is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Approved by is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
    // {
    //   span: 24,
    //   component: "editor",
    //   label: "Description",
    //   name: "description",
    //   placeholder: "Enter description",
    //   rows: 5,
    // },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "attachment",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: {
              ...values,
              reconciliation: data?.reconciliation,
              payment_schedule: data?.payment_schedule
            },
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
