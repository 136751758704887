import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input, Table } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import qs from "qs";
import _ from "lodash";
import { MAX_PAGINATION_LIMIT } from "../../../../../../constants";
import { axios } from "../../../../../../App";
import { DataTable } from "../../../../components";
import { ReloadOutlined, PlusOutlined } from "@ant-design/icons";
import config from "./config";
import { Column } from "@ant-design/plots";
import * as XLSX from "xlsx";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';


const BudgetTrackerProjectList = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const budgetTableRef = useRef();

  // Extract the 'project' parameter from the search query
  const project = searchParams.get('project');
  const projectName = searchParams.get('name');

  const [data, setData] = useState([]);
  const [projectData, setprojectData] = useState(null)
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const [chartData, setchartData] = useState([])
  const [subMilestoneData, setSubMilestoneData] = useState([]);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        filters: {
          budget_tracker: id,
        },
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      const projectQueryObject = {
        filters: {
          id: project,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `budget-tracker-phases?${qs.stringify(queryObject)}`
      );

      const projectResponse = await axios.get(
        `projects?${qs.stringify(projectQueryObject)}`
      );

      console.log('response', projectResponse);

      if (projectResponse?.data?.data?.length > 0) {
        setprojectData(projectResponse?.data?.data?.[0])
      }

      if (response?.data) {
        setData(
          response?.data?.data?.map((item, index) => ({
            id: item?.id,
            index: index,
            ...item?.attributes,
          }))
        );

        setchartData(
          response?.data?.data?.flatMap((item, i) => {
            // Extract milestone names, join them if there are multiple
            const milestoneNames = item?.attributes?.milestone?.data
              ?.map(m => m?.attributes?.name)
              .join(', ') || `ID ${item?.id}`;

            const phase = `R${i}`;

            return [
              {
                name: phase, // Use milestone names as the x-axis label
                type: 'Estimated Budget',
                value: item?.attributes?.total_estimated_budget,
              },
              {
                name: phase,
                type: 'Actual Budget',
                value: item?.attributes?.total_actual_budget,
              },
            ];
          })
        );


        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      // Extract the last object's sub_milestone data
      const lastObject = data?.[data.length - 1];
      const subMilestones = lastObject?.sub_milestone || {};


      const totalActualBudget = Object.values(subMilestones)
        .flatMap((milestoneGroup) =>
          milestoneGroup.map((milestone) => milestone.actual_budget)
        )
        .reduce((sum, budget) => sum + budget, 0);

      const totalEstimatedBudget = Object.values(subMilestones)
        .flatMap((milestoneGroup) =>
          milestoneGroup.map((milestone) => milestone.estimated_budget)
        )
        .reduce((sum, budget) => sum + budget, 0);

      // Flatten and transform the sub_milestone data into a chart-friendly format
      const chartData = Object.values(subMilestones).flatMap((milestoneGroup) =>
        milestoneGroup.map((milestone) => ({
          subMilestone: milestone.sub_milestone,
          actualBudget: milestone.actual_budget,
          estimatedBudget: milestone.estimated_budget,
        }))
      );

      setSubMilestoneData(chartData);
    }
  }, [data]);

  const refreshData = () => {
    onSearch();
  };

  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`budget-tracker-phases/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.entityNames?.singular}`,
          description: `Remove ${config?.entityNames?.singular} successfully!`,
        });

        fetchData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    fetchData({
      name: {
        $containsi: searchValues?.keyword?.toLowerCase(),
      },
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const barConfig = {
    data: chartData,
    isStack: true,
    xField: 'name',
    yField: 'value',
    seriesField: 'type',
    isGroup: true, // Display grouped bars for each milestone
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
    },
    yAxis: {
      label: {
        formatter: v => v.toLocaleString(),
      },
    },
    tooltip: {
      formatter: datum => ({ name: datum.type, value: datum.value.toLocaleString() }),
    },
    legend: { position: 'bottom' },
    color: ({ type }) => (type === 'Estimated Budget' ? '#2E4494' : '#CF6C58'),
  };

  const bar2Config = {
    data: subMilestoneData,
    xField: 'subMilestone',
    yField: 'value',
    seriesField: 'type',
    isGroup: true, // Display grouped bars for each milestone
    columnWidthRatio: 0.5,
    legend: { position: 'bottom' },
    color: ({ type }) => (type === 'Available Budget' ? '#2E948E' : '#6A58CF'),
  };

  const formattedData = subMilestoneData.flatMap((item) => [
    { subMilestone: item.subMilestone, type: 'Available Budget', value: item.actualBudget },
    { subMilestone: item.subMilestone, type: 'Allocated Budget', value: item.estimatedBudget },
  ]);


  const getColumnLetter = (colNum) => {
    let temp, letter = '';
    while (colNum > 0) {
      temp = (colNum - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      colNum = (colNum - temp - 1) / 26;
    }
    return letter;
  };


  // Utility function to calculate totals
  const calculateTotal = (item, field) => {
    let total = 0;
    Object.values(item.sub_milestone).forEach((subMilestones) => {
      subMilestones.forEach((subItem) => {
        total += parseFloat(subItem[field]) || 0;
      });
    });
    return total;
  };

  const exportToExcel = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Budget');

    // Define styles
    const headerFont = { size: 24, bold: true };
    const subHeaderFont = { bold: true };
    const centerAlignment = { horizontal: 'center', vertical: 'middle' };
    const highlightFill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFE0' }, // Light yellow background
    };
    const highlightHeadFill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'CEEBF6' }, // Light yellow background
    };

    // Define number of columns per phase and spacing
    const columnsPerPhase = 12; // Adjusted based on your table structure
    const spacing = 1; // Number of empty columns between phases

    // Set column widths for better readability
    // Total columns = (columnsPerPhase + spacing) * number of phases
    const totalPhases = data.length;
    const totalColumns = totalPhases * (columnsPerPhase + spacing);

    for (let i = 1; i <= totalColumns; i++) {
      worksheet.getColumn(i).width = 13; // Set a default width; adjust as needed
    }

    const startRow = 1; // Ensures alignment of all phases in the same row
    data?.forEach((item, index) => {
      const phaseIndex = index;
      const startCol = phaseIndex * (columnsPerPhase + spacing) + 1;
      const endCol = startCol + columnsPerPhase - 1;


      // Add R{index} as a merged header with larger font
      const rHeaderCellStart = getColumnLetter(startCol);
      const rHeaderCellEnd = getColumnLetter(endCol);
      worksheet.mergeCells(`${rHeaderCellStart}${startRow}:${rHeaderCellEnd}${startRow}`);
      const headerCell = worksheet.getCell(`${rHeaderCellStart}${startRow}`);
      headerCell.value = `R${index}`;
      headerCell.font = headerFont;
      headerCell.alignment = centerAlignment;
      headerCell.fill = highlightFill;

      // Add table headers
      const headers = [
        'Sub-Milestone',
        '', // Empty column
        'LABOUR COST',
        'MATERIAL COST',
        'TOTAL COST',
        'Project Cost',
        '', // Empty column
        'COST PER SQ FT ON CONSTRUCTION AREA',
        'COST PER SQ FT ON CARPET AREA',
        // 'ESTIMATED BUDGET',
        // 'ACTUAL BUDGET',
        'TOTAL CONSTRUCTION AREA',
        'TOTAL CARPET AREA',
        '', // Empty column
        '', // Empty column
      ];

      headers.forEach((header, i) => {
        const cell = worksheet.getCell(`${getColumnLetter(startCol + i)}${startRow + 1}`);
        cell.value = header;
        cell.font = subHeaderFont;
        cell.alignment = centerAlignment;
        cell.fill = highlightHeadFill;
        // Optionally, add borders
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });

      // Add sub-milestones only once (for the first phase)
      let currentRow = startRow + 2;
      Object.entries(item.sub_milestone).forEach(([milestoneId, subItems]) => {
        // Add Milestone Name row
        const milestoneName = item.milestone?.data?.find(
          (milestoneItem) => milestoneItem?.id == milestoneId
        )?.attributes?.name || '';
        const milestoneRowNumber = worksheet.rowCount + 1;
        worksheet.mergeCells(`${getColumnLetter(startCol)}${currentRow}:${getColumnLetter(endCol)}${currentRow}`);
        const milestoneCell = worksheet.getCell(`${getColumnLetter(startCol)}${currentRow}`);
        milestoneCell.value = milestoneName;
        milestoneCell.font = { bold: true, size: 14 };
        milestoneCell.alignment = centerAlignment;
        milestoneCell.fill = highlightFill;

        currentRow += 1;

        // Add sub-milestones
        subItems.forEach((subItem, subIndex) => {
          const dataRowNumber = worksheet.rowCount + 1;
          const rowValues = [
            subItem.sub_milestone,
            '', // Empty column
            subItem.estimated_labour_cost,
            subItem.estimated_material_cost,
            Number(subItem.estimated_labour_cost) + Number(subItem.estimated_material_cost),
            '',
            '', // Empty column
            item.construction_area_cost,
            item.carpet_area_cost,
            // subItem.estimated_budget,
            // subItem.actual_budget,
            '',
            '',
            '', // Empty column
            '', // Empty column
          ];
          rowValues.forEach((value, i) => {
            const cell = worksheet.getCell(`${getColumnLetter(startCol + i)}${currentRow}`);
            cell.value = value;
            cell.alignment = { ...centerAlignment, indent: i === 0 ? 1 : 0 };
            // Optionally, add borders
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          });

          currentRow += 1;
        });
      });


      // Add Total row for each phase
      const totalRowValues = [
        'Total',
        '', // Empty column
        calculateTotal(item, 'estimated_labour_cost'),
        calculateTotal(item, 'estimated_material_cost'),
        '', // Optionally, calculate total cost
        projectData?.attributes?.estimated_budget ?? '0',
        '', // Empty column
        '', // Empty columns for construction area totals if needed
        '',
        // calculateTotal(item, 'estimated_budget'),
        // calculateTotal(item, 'actual_budget'),
        item.construction_area,
        item.carpet_area,
        '',
        '',
      ];
      totalRowValues.forEach((value, i) => {
        const cell = worksheet.getCell(`${getColumnLetter(startCol + i)}${currentRow}`);
        cell.value = value;
        cell.font = { bold: true };
        cell.alignment = centerAlignment;
        cell.fill = highlightHeadFill;
        // Optionally, add borders
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });

      // Add some space before the next phase (handled by column spacing)
    });

    // Optionally, add Grand Total or other summary rows

    // Generate Excel file buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Save the file
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `${projectName}-Budget.xlsx`);
  };

  useEffect(() => {
    fetchData(filters);
  }, []);

  console.log('Fetching records', subMilestoneData)

  return (
    <>
      <DataTable
        data={data}
        config={{
          ...config,
          breadcrumbs: [...config?.breadcrumbs, {
            path: "",
            title: projectName,
          }],
          pageTitle: projectName,
          columns: config?.columns
            ?.map((column) => ({
              ...column,
              ...getColumnProps(column),
            }))
            ?.concat({
              title: "Actions",
              fixed: "right",
              width: 100,
              selectable: false,
              render: ({ id: ro_id }) => {
                return (
                  <Space>
                    <Tooltip
                      placement="bottom"
                      title={`Edit ${config?.entityNames?.singular}`}
                    >
                      <Button
                        icon={<EditOutlined />}
                        onClick={() =>
                          navigate(
                            `/budget-tracker/list/edit/${id}?project=${project}&tracker=${ro_id}&name=${projectName}`
                          )
                        }
                      />
                    </Tooltip>

                    <Popconfirm
                      title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => deleteItem(ro_id)}
                    >
                      <Tooltip
                        placement="bottom"
                        title={`Remove ${config?.entityNames?.singular}`}
                      >
                        <Button type="danger" icon={<DeleteOutlined />} />
                      </Tooltip>
                    </Popconfirm>
                  </Space>
                );
              },
            }),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/budget-tracker/list/add/${id}?project=${project}&tracker=${data[data?.length - 1]?.id}&name=${projectName}`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Input
              value={searchValues?.keyword}
              placeholder="Search for keyword..."
              onChange={(e) =>
                setSearchValues((prev) => ({
                  ...prev,
                  keyword: e.target.value,
                }))
              }
            />

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
                <Button onClick={() => exportToExcel(data)}>Generate Excel</Button>
              </Space>
            </div>
          </div>
        }
        buttons={
          <div>
            <Tooltip placement="top" title="Add new item">
              <Button
                icon={<PlusOutlined />}
                onClick={() => navigate(`/budget-tracker/list/add/${id}?project=${project}&tracker=${data[data?.length - 1]?.id}&name=${projectName}`)}
              />
            </Tooltip>
          </div>
        }
      />

      <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <div id="barGraph" style={{ flex: 1 }}>
          <Column {...barConfig} />
        </div>

        <div style={{ flex: 1 }}>
          <Column {...bar2Config} data={formattedData} />
        </div>

      </div>
    </>
  );
};

export default BudgetTrackerProjectList;
