import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { Breadcrumb, Button, Col, Collapse, Form, Input, InputNumber, PageHeader, Row, Select, Space, notification } from "antd";
import { Helmet } from "react-helmet";
import FormElement from "../../../components/FormElement";
import { v4 } from "uuid";
import ReactQuill from "react-quill";
import { Box } from "@chakra-ui/react";

const { Option } = Select;
const { Panel } = Collapse;

const VendorBoqAdd = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [allValues, setAllValues] = useState({});
  const [boq_item, setBoq_item] = useState([{
    id: v4(),
    material_group: null,
    material_item: null,
    material_unit: null,
    quantity: "",
    estimated_labour_cost: "",
    estimated_material_cost: "",
    estimated_budget: "",
    item_description: "",
  }])
  // State to hold filtered material items for each boq item
  const [filteredMaterialItems, setFilteredMaterialItems] = useState({});

  const [values, setValues] = useState({
    status: ["Pending", "Approved", "Rejected"]?.map((item) => ({
      value: item,
      label: item,
    })),
    material_unit: [],
    all_material_item: [],
  });


  const contentTypes = [
    {
      slug: "material-groups",
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_group: data,
        })),
    },
    {
      slug: "material-items",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
        material_group: item?.material_group,
        material_unit: item?.material_unit
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_material_item: data,
        })),
    },
    {
      slug: "material-units",
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_unit: data,
        })),
    },
    {
      slug: "tasks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          task: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          projects: data,
        })),
    },
    {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          approved_by: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddBoq = () => {
    setBoq_item((prev) =>
      prev?.concat({
        id: v4(),
        material_group: null,
        material_item: null,
        material_unit: null,
        quantity: "",
        estimated_labour_cost: "",
        estimated_material_cost: "",
        estimated_budget: "",
        item_description: "",
      })
    );
  };

  // State to hold filtered material items for each boq item
  const onEditBoq = (id, key, value) => {
    setBoq_item((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          const updatedItem = { ...item, [key]: value };

          // Filter material items based on selected material group
          if (key === 'material_group') {
            const newfilteredMaterialItems = { ...filteredMaterialItems };
            newfilteredMaterialItems[id] = values?.all_material_item
              ?.filter(
                (item) => item?.material_group?.data?.id === value
              )

            setFilteredMaterialItems(newfilteredMaterialItems);

            // Reset the material_item if the group changes
            updatedItem.material_item = null;
          }

          // Auto-select material unit based on selected material item
          if (key === 'material_item') {
            const selectedItem = values?.all_material_item?.find(
              (material) => material?.value === value
            );

            console.log('selected', selectedItem, values?.all_material_item, value)

            if (selectedItem) {
              // Set the material_unit based on selected material item
              updatedItem.material_unit = selectedItem.material_unit?.data?.id || null;
            }
          }

          // Calculate estimated_budget
          if (key === "estimated_labour_cost" || key === "estimated_material_cost") {
            const labourCost = parseFloat(updatedItem.estimated_labour_cost) || 0;
            const materialCost = parseFloat(updatedItem.estimated_material_cost) || 0;
            updatedItem.estimated_budget = labourCost + materialCost;
          }

          return updatedItem;
        }
        return item;
      })
    );
  };

  const onRemoveBoq = (id) => {
    setBoq_item((prev) => prev?.filter((item) => item?.id !== id));
    setFilteredMaterialItems((prev) => {
      const newfilteredMaterialItems = { ...prev };
      delete newfilteredMaterialItems[id];
      return newfilteredMaterialItems;
    });


    notification["success"]({
      message: `Remove Boq`,
      description: `Remove Boq successfully!`,
    });
  };

  const handleValuesChange = (_, inputValues) => {
    setAllValues((prev) => ({
      ...prev,
      ...inputValues,
    }));
  };

  const handleFinish = async () => {
    setSubmitting(true);
    const transform_boq = boq_item?.map(item => ({
      ...item,
      full_data: {
        material_group: values?.material_group?.find(data => data?.value === item?.material_group),
        material_item: values?.all_material_item?.find(data => data?.value === item?.material_item),
        material_unit: values?.material_unit?.find(data => data?.value === item?.material_unit)
      }
    }))


    const data = {
      name: allValues?.name,
      boq_number: allValues?.boq_number,
      date: allValues?.date,
      project: allValues?.projects,
      approved_by: allValues?.approved_by,
      boq_item: transform_boq,
    }

    console.log('data', data)
    const response = await config?.actions?.onFinish(data);

    if (response) {
      if (response?.success) {
        notification["success"]({
          message: config?.title,
          description: response?.message,
        });
        config?.actions?.onBack();
      } else {
        notification["error"]({
          message: config?.title,
          description: response?.message,
        });
      }
    }

    setSubmitting(false);
  };

  console.log('all values', boq_item)

  return (
    <>

      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      <Form
        form={form}
        name={config?.id}
        layout="vertical"
        onFinish={handleFinish}
        style={styles.form}
        scrollToFirstError
        onValuesChange={handleValuesChange}
      >
        <Box display={{ base: "none", md: "block", lg: "block" }}>
          <Row gutter={24}>
            {config?.components.map((component) => (
              <Col span={component?.span} key={component?.name}>
                <FormElement
                  {...component}
                  values={values}
                  form={form}
                />
              </Col>
            ))}
          </Row>
        </Box>

        {boq_item?.map((data, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            <Collapse>
              <Panel header={`Item ${index + 1}`} key="uniqueKey">
                <div style={{ border: '1px solid #e8e8e8', padding: '20px', marginBottom: '10px' }}>
                  <Row gutter={24}>
                    {/* Material Group Dropdown */}
                    <Col span={6}>
                      <Select
                        placeholder="Select Material Group"
                        value={data?.material_group}
                        onChange={(value) =>
                          onEditBoq(data?.id, "material_group", value)
                        }
                        options={values?.material_group}
                        style={{ width: "100%" }}
                      />
                    </Col>

                    {/* Item Dropdown */}
                    <Col span={6}>
                      <Select
                        placeholder="Select Material Item"
                        value={data?.material_item}
                        onChange={(value) => onEditBoq(data.id, "material_item", value)}
                        options={filteredMaterialItems[data.id] || []}
                        style={{ width: "100%" }}
                      />
                    </Col>

                    {/* Unit Input */}
                    <Col span={6}>

                      <Select
                        disabled
                        placeholder="Select Material Unit"
                        value={data?.material_unit}
                        onChange={(value) =>
                          onEditBoq(data?.id, "material_unit", value)
                        }
                        options={values?.material_unit}
                        style={{ width: "100%" }}
                      />
                    </Col>

                    {/* Quantity Input */}
                    <Col span={6}>
                      <Input
                        type="number"
                        placeholder="Enter Quantity"
                        value={data?.quantity}
                        onChange={(e) =>
                          onEditBoq(data?.id, "quantity", e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginTop: '10px' }}>
                    {/* Estimated Labour Cost */}
                    <Col span={8}>

                      <Input
                        type="number"
                        placeholder="Enter Labout Cost"
                        value={data?.estimated_labour_cost}
                        onChange={(e) =>
                          onEditBoq(data?.id, "estimated_labour_cost", e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    </Col>

                    {/* Estimated Material Cost */}
                    <Col span={8}>

                      <Input
                        type="number"
                        placeholder="Enter Material Cost"
                        value={data?.estimated_material_cost}
                        onChange={(e) =>
                          onEditBoq(data?.id, "estimated_material_cost", e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    </Col>

                    {/* Estimated Budget */}
                    <Col span={8}>
                      <Input
                        type="number"
                        disabled
                        placeholder="Estimated Budget"
                        value={data?.estimated_budget}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginTop: '10px' }}>
                    <Col span={24}>
                      {/* <Input
                        type="input"
                        placeholder="Description"
                        value={data?.description}
                        onChange={(value) =>
                          onEditBoq(data?.id, "item_description", value)
                        }
                        rows={5}
                        style={{ width: "100%" }}
                      /> */}
                      <ReactQuill theme="snow" preserveWhitespace={true}
                        onChange={(value) =>
                          onEditBoq(data?.id, "item_description", value)
                        }
                        placeholder="Description"
                        rows={5} />
                    </Col>
                  </Row>

                </div>


                {/* Delete Button */}
                <Space style={styles.buttons}>
                  <Button type="danger" onClick={() => onRemoveBoq(data?.id)}>
                    Delete
                  </Button>
                </Space>
              </Panel>
            </Collapse>
          </div>
        ))}
        <Space style={{ width: '100%', marginBottom: '20px' }}>
          <Space style={{ width: '100%' }}>
            <Button type="primary" variant="filled" onClick={() => onAddBoq()} >
              Add BOQ Item
            </Button>
          </Space>
        </Space>

        <Space style={styles.buttons}>
          <Space>
            <Button onClick={config?.actions?.onBack}>Cancel</Button>

            <Button type="primary" htmlType="submit" loading={submitting}>
              Save
            </Button>
          </Space>
        </Space>
      </Form>
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
  },
  loaderContainer: {
    textAlign: "center",
    padding: 12,
  },
  header: {
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  errors: {
    marginBottom: 24,
  },
  errorsTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 6,
  },
  alert: {
    marginBottom: 6,
  },
};

export default VendorBoqAdd;
