import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { Empty, Input, Button, PageHeader, Breadcrumb, Spin, Modal, Select, Table, Col } from "antd";
import { axios } from "../../../../../App";
import { Link, useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import qs from "qs";
import { BASE_API_URL, STRAPI_URL } from "../../../../../constants";
import parse from 'html-react-parser'
import ReactQuill from "react-quill";
import { FaFileInvoice } from "react-icons/fa";

const { TextArea } = Input;
const { Option } = Select;


const Page = ({
  inputId = null,
  showsCancel = true,
  showsHeader = true,
  mode = "normal",
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const config = makeConfig({ navigate });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [workItemsRemarks, setWorkItemsRemarks] = useState("");
  const [termsRemarks, setTermsRemarks] = useState("");
  const [paymentSchedulesRemarks, setPaymentSchedulesRemarks] = useState("");
  const [letterheadFileName, setLetterheadFileName] = useState(null);
  const [showsModal, setShowsModal] = useState(false);
  const [letterheads, setLetterheads] = useState([]);
  const [terms, setTerms] = useState([]);
  const [tableTerms, setTablTerms] = useState([])
  const [fileTerms, setFileTerms] = useState([])


  const componentRef = useRef();

  const fetchData = async (quotationId) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
      };

      const response = await axios.get(
        `/sub-contractor-quotations/${quotationId}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        const dataObject = response?.data?.data?.attributes;

        setData(dataObject);
        setWorkItemsRemarks(dataObject?.work_items_remarks);
        setTermsRemarks(dataObject?.terms_remarks);
        setPaymentSchedulesRemarks(dataObject?.payment_schedules_remarks);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLetterheads = async () => {
    try {
      const resp = await axios.get(`/letterheads?populate=*`);

      if (resp?.data?.data?.length > 0) {
        const results = resp?.data?.data?.map((record, index) => {
          let fileName =
            record?.attributes?.image_file?.data?.attributes?.url?.split(
              "/"
            )[2] || "";

          return {
            id: record?.id,
            name: record?.attributes?.name,
            file_name: fileName,
          };
        });

        setLetterheads(results);
      }
    } catch (error) { }
  };

  const getDeliveryAddress = (addressObject) => {
    return `${addressObject?.name} (${addressObject?.address_line_1}, ${addressObject?.address_line_2})`;
  };

  useEffect(() => {
    if (mode === "normal") {
      fetchData(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (mode !== "normal" && inputId) {
      fetchData(inputId);
    }

    fetchLetterheads()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputId]);

  // PDF Generation function
  const generatePdf = () => {
    // Add the CSS to hide elements
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
        .hide-in-pdf {
            display: none !important;
        }
    `;
    document.head.appendChild(style);

    setTimeout(() => {
      var element = componentRef?.current?.innerHTML;

      var opt = {
        margin: [0.03, 0, 0.3, 0],
        filename: "subcontractor_work_order" + ".pdf",
        image: { type: "jpeg", quality: 0.5 },
        pagebreak: { avoid: "tr", mode: "css", before: "#nextpage1" },
        html2canvas: {
          scale: 5,
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "a3", orientation: "portrait" },
        compressImage: false,
      };

      import("html2pdf.js").then((html2pdf) => {
        /* html2pdf().set(opt).from(element).save(); */
        html2pdf.default().set(opt).from(element).save();
      });

      // Remove the style after generating the PDF
      setTimeout(() => {
        document.head.removeChild(style);
      }, 0);

    }, 10);
  };

  const onSubmit = () => {
    setShowsModal(false);
    generatePdf();

  };

  // Initialize terms state with data from props
  useEffect(() => {
    setTerms(data?.terms?.[0]?.term_and_condition || []);
    setTablTerms(data?.terms?.[0]?.term_and_condition?.table_terms || [])
    setFileTerms(data?.terms?.[0]?.term_and_condition?.file_terms || [])

  }, [data]);


  const total_cgst = data?.work_item_notes?.reduce((acc, item) => {
    return acc + item?.cqst; // Add budget * quantity to the accumulator
  }, 0);

  const total_sgst = data?.work_item_notes?.reduce((acc, item) => {
    return acc + item?.sqst; // Add budget * quantity to the accumulator
  }, 0);

  const total_igst = data?.work_item_notes?.reduce((acc, item) => {
    return acc + item?.igst; // Add budget * quantity to the accumulator
  }, 0);

  const basic_contract_value = data?.work_items?.reduce((acc, item) => {
    const budget = parseFloat(item.estimated_budget) || 0; // Convert estimated_budget to a number
    const quantity = parseFloat(item.quantity) || 0; // Convert quantity to a number
    return acc + (budget * quantity); // Add budget * quantity to the accumulator
  }, 0);

  const total_cgst_value = basic_contract_value * (total_cgst / 100);
  const total_sgst_value = basic_contract_value * (total_sgst / 100);
  const total_igst_value = basic_contract_value * (total_igst / 100);

  const columns = [
    {
      title: 'Term Name',
      dataIndex: 'table_term_name',
      render: (_, record) => (
        <Input
          value={record.table_term_name}
          onChange={(e) => handleInputChange(e, record.id, 'table_term_name')}
          placeholder="Enter term name"
          disabled
        />
      )
    },
    {
      title: 'Term Details',
      dataIndex: 'table_term_details',
      render: (_, record) => (
        <Input.TextArea
          value={record.table_term_details}
          onChange={(e) => handleInputChange(e, record.id, 'table_term_details')}
          placeholder="Enter term details"
          autoSize={{ minRows: 1, maxRows: 8 }} // Adjusts height dynamically from 1 to 5 rows
        />
      )
    },
  ];

  const fileUploadColumns = [
    {
      title: 'Term Name',
      dataIndex: 'details',
      render: (_, record) => (
        <Input
          value={record.details}
          onChange={(e) => handleInputFileChange(e, record.id, 'details')}
          placeholder="Enter term name"
        />
      )
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'file',
      render: (_, record) => {
        return (
          <div>
            <Input
              className="hide-in-pdf"
              type="file"
              onChange={(e) => onFileChange(e, record, 'remark')}
              style={{ marginBottom: '5px' }}
            />
            {record.remark && (
              <a
                href={`${STRAPI_URL}${record?.remark?.url}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                <FaFileInvoice style={{ fontSize: '20px', color: 'red' }} />
                {record?.remark?.name}
              </a>
            )}
          </div>
        )
      },
    },
  ];


  // State to hold filtered material items for each boq item
  const handleInputChange = (e, id, field) => {
    const { value } = e.target;
    setTablTerms((prevTerms) =>
      prevTerms.map((term) =>
        term.id === id ? { ...term, [field]: value } : term
      )
    );
  };

  const onFileChange = async (e, record, field) => {
    try {
      const file = e.target.files?.[0];
      let fileData = new FormData();
      fileData.append('files', file);

      const response = await axios.post('/upload', fileData);

      if (response?.data?.length > 0) {
        const uploadedFile = response.data[0];

        // setTerms((prevState) => {
        //   return {
        //     ...prevState, // Spread the existing `terms` object
        //     terms: prevState.terms.map((term) =>
        //       term.id === record.id ? { ...term, file: uploadedFile } : term
        //     ), // Update the nested `terms` array
        //   };
        // });

        setFileTerms((prevTerms) =>
          prevTerms.map((term) =>
            term.id === record?.id ? { ...term, [field]: uploadedFile } : term
          )
        );

      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputFileChange = (e, id, field) => {
    const { value } = e.target;
    setFileTerms((prevTerms) =>
      prevTerms.map((term) =>
        term.id === id ? { ...term, [field]: value } : term
      )
    );
  };



  useEffect(() => {
    // add one more term that is Ctntract amount
    if (basic_contract_value > 0) {

      setTimeout(() => {

        setTerms((prevData) => ({
          ...prevData,
          terms: [
            ...prevData.terms,
            {
              term_name: 'Financials:',
              term_details: `
              <div>
                <h3>1 Contract Amount:</h3>
                <p>
                  This agreement amount shall include all transportation, loading and unloading, scaffolding, consumables, all lead and lift resources and tackles, Taxes, cesses, duties, cargo, damages, Safety measures and Insurance, all overheads and profits additionally the labour, machinery, fuel, mathadi, etc. total.
                </p>
                <p>
                  <strong>Basic Value of Contract:</strong> &nbsp;&nbsp;&nbsp; ${basic_contract_value}/-
                </p>
                <p>
                  <strong>Total GST Amount:</strong> &nbsp;&nbsp;&nbsp;
                  ${total_cgst > 0 ? `${total_cgst_value}/- CGST` : ''}
                  ${total_sgst > 0 ? `+ ${total_sgst_value}/- SGST` : ''}
                  ${total_igst > 0 ? `+ ${total_igst_value}/- IGST` : ''}
                </p>
                <p>
                  <strong>Total Contract Amount:</strong> &nbsp;&nbsp;&nbsp; ${data?.grand_total_amount}/-
                </p>

                <h3>2 Taxes:</h3>
                <p>
                  GST/Relevant Tax Registration number needs to be clearly mentioned in the contractor's bill. 
                  The Contractor shall provide all the documentary details as may be required by the client. 
                  The Contractor is solely accountable for payment of taxes with regard to the Scope of Work and this work order. 
                  Taxes as applicable shall be paid as per government rules & regulations. T.D.S shall be deducted from each R.A. bill.
                </p>
                <p>
                  The Client shall issue a TDS consolidated certificate on a quarterly basis upon request by the Contractor to the Client in writing.
                </p>

                <h3>3 Rate:</h3>
                <p>
                  The rates shall be firm throughout the term of the Contract and shall not be subject to any escalation due to increase 
                  in the price of materials, Labour Wages, or on any other account, etc., irrespective of all leads at all heights and all levels and 
                  any change in quantity, till the completion of work, even if the work is delayed for any reasons attributable to either of the Parties.
                </p>

                <h3>4 Mode of Measurement:</h3>
                <p>
                  As mentioned in BOQ unit & specifications. Quantity measured shall be based on Actual work done at the site.
                </p>
              </div>
              `
            }
          ]
        }));


      }, 2000);
    }

  }, [basic_contract_value])

  if (loading) {
    return (
      <div style={styles.container}>
        <div style={styles.loadingContainer}>
          <Spin />
        </div>
      </div>
    );
  }


  console.log('jelllllll', terms?.terms)

  return (
    <div style={styles.container}>
      <Helmet>
        <title>Sub Contractor Quotation</title>
      </Helmet>

      {showsHeader && (
        <>
          <Breadcrumb style={styles.breadcrumbs}>
            {config?.breadcrumbs?.map((breadcrumb, index) => (
              <Breadcrumb.Item key={index}>
                <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>

          <PageHeader
            title={config?.title}
            style={styles.pageHeader}
            onBack={config?.actions?.onBack}
          ></PageHeader>
        </>
      )}

      <div style={styles.formContainer} ref={componentRef}>
        {letterheadFileName ? (
          <img
            src={`${STRAPI_URL}/uploads/${letterheadFileName}`}
            alt="Letterhead"
            style={{
              width: '100%',
              height: '150px',
              zIndex: -1, // Ensure it is behind other content
              objectFit: 'cover',
            }}
          />
        ) : (

          <div className="public-form-header">
            <table
              cellPadding={0}
              cellSpacing={0}
              style={{
                width: "100%",
                border: "0px solid #ddd",
                marginBottom: "10px",
              }}
            >
              <tr style={{ borderBottom: "1px solid #eee" }}>
                <td
                  className="text-center"
                  style={{
                    background:
                      "url(/images/form-header-left.jpeg) no-repeat top left",
                    backgroundSize: "80%",
                    width: "30%",
                  }}
                >
                  <img
                    title="QuickSO"
                    src="/images/form-header-logo.png"
                    alt="QuickSO"
                    style={{
                      height: "75px",
                      width: "auto",
                      objectFit: "contain",
                      margin: "0 auto",
                    }}
                  />
                </td>

                <td
                  style={{
                    background:
                      "url(/images/form-header-right.jpeg) no-repeat top left",
                    backgroundSize: "100%",
                  }}
                >
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    style={{
                      width: "100%",
                      border: "0px solid #ddd",
                      marginBottom: "0px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          colSpan={2}
                          style={{ borderBottom: "2px dashed #ddd" }}
                        >
                          <h1
                            className="text-left"
                            style={{
                              color: "#ffffff",
                              fontSize: "30px",
                              padding: "20px 20px",
                              textTransform: "uppercase",
                              margin: 0,
                            }}
                          >
                            SUB CONTRACTOR WORK ORDER
                          </h1>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <h2
                            className="text-left"
                            style={{
                              color: "#ffffff",
                              fontSize: "16px",
                              padding: "10px 20px",
                              fontWeight: 300,
                            }}
                          >
                            Serial Number
                            <br />
                            <b>
                              VPO-
                              {
                                data?.sc_requirement_form?.data?.attributes?.uid?.split(
                                  "-"
                                )?.[0]
                              }
                              -
                              {
                                data?.sub_contractor?.data?.attributes?.uid?.split(
                                  "-"
                                )?.[0]
                              }
                            </b>
                          </h2>
                        </td>

                        <td>
                          <h2
                            className="text-left"
                            style={{
                              color: "#ffffff",
                              fontSize: "16px",
                              padding: "10px 20px",
                              fontWeight: 300,
                            }}
                          >
                            Date
                            <br />
                            <b>
                              {moment(data?.createdAt).format(
                                "DD-MM-YYYY, HH:mm"
                              )}
                            </b>
                          </h2>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        )}


        <div className="public-form-body">
          <div className="public-form-section">
            <h2 className="public-form-section-title">General Information</h2>

            <table className="public-form-general-info">
              <tr>
                <td>Sub Contractor</td>
                <td>{data?.sub_contractor?.data?.attributes?.name}</td>
              </tr>

              <tr>
                <td>Project</td>
                <td>{data?.project?.data?.attributes?.name}</td>
              </tr>

              <tr>
                <td>Delivery Address</td>
                <td>
                  {getDeliveryAddress(
                    data?.delivery_address?.data?.attributes
                  ) || "N/A"}
                </td>
              </tr>

              <tr>
                <td>Requirement Form</td>
                <td>{data?.heading}</td>
              </tr>

              <tr>
                <td>Urgency Level</td>
                <td>{data?.urgency_level}</td>
              </tr>

              <tr>
                <td>Expected Delivery Date</td>
                <td>
                  {data?.expected_delivery_date
                    ? moment(data?.expected_delivery_date)?.format("DD-MM-YYYY")
                    : "N/A"}
                </td>
              </tr>

              <tr>
                <td>Delivery Date</td>
                <td>
                  {data?.delivery_date
                    ? moment(data?.delivery_date)?.format("DD-MM-YYYY")
                    : "N/A"}
                </td>
              </tr>

              <tr>
                <td>Work Specifications</td>
                <td>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        data?.sc_requirement_form?.data?.attributes?.remarks,
                    }}
                  />
                </td>
              </tr>
            </table>

            <table className="public-form-general-info">
              <tbody>
                <tr>
                  <td
                    style={{
                      width: "33%",
                      verticalAlign: "top",
                      padding: "10px",
                      border: "1px solid #ddd",
                      fontWeight: '400'
                    }}
                  >
                    <strong>Sub Contracter Details</strong>
                    <br /><br />
                    <strong>Name: </strong> {data?.sub_contractor?.data?.attributes?.name}
                    <br />
                    <strong>Address: </strong> {data?.sub_contractor?.data?.attributes?.address_line_1},
                    {data?.sub_contractor?.data?.attributes?.address_line_2}
                    <br />
                    <strong>GSTIN:</strong> {" "}
                    {data?.sub_contractor?.data?.attributes?.gsin_no}
                  </td>

                  {/* {data?.client?.length > 0 && (
                  <td
                    style={{
                      width: "33%",
                      verticalAlign: "top",
                      padding: "10px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <strong>Name and Address of Buyer</strong>
                    <br />
                    {data?.client[0]?.client_name}
                    <br />
                    {data?.client[0]?.po_address}
                    <br />
                    <strong>GSTIN:</strong> {data?.client[0]?.gstin_number}
                    <br />
                    <strong>Place of Supply:</strong>{" "}
                    {data?.client[0]?.place_of_supply}
                  </td>
                )} */}

                  <td
                    style={{
                      width: "33%",
                      verticalAlign: "top",
                      padding: "10px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <strong>Delivery Address</strong>
                    <br /><br />
                    <strong>Name: </strong> {data?.delivery_address?.data?.attributes?.name}
                    <br />
                    <strong>Address: </strong> {data?.delivery_address?.data?.attributes?.address_line_1},{" "}
                    {data?.delivery_address?.data?.attributes?.address_line_2}
                    <br />
                    {/* <strong>GSTIN:</strong> {" "}
                    {data?.delivery_address?.data?.attributes?.gsin_no} */}

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {data?.work_items?.length > 0 && (
            <div className="public-form-section">
              <h2 className="public-form-section-title">Work Items</h2>
              <div style={{ overflow: "auto" }}>
                <table className="public-form-table">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th style={{ width: "20%" }}>Item Description</th>
                      {/* <th>Work Group</th>
                      <th>Work Item</th> */}
                      <th>Work Unit</th>
                      <th>Quantity</th>
                      <th style={{ width: "10%" }}>Rate(₹)</th>
                      <th style={{ width: "10%" }}>CGST (%)</th>
                      <th style={{ width: "10%" }}>SGST (%)</th>
                      <th style={{ width: "10%" }}>IGST (%)</th>
                      <th style={{ width: "10%" }}>Total Amount(₹)</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data?.work_items?.length > 0 &&
                      data?.work_items?.map((workItem, index) => (
                        <tr key={workItem?.id}>
                          <td className="text-center text-bold">{index + 1}</td>
                          <td dangerouslySetInnerHTML={{ __html: workItem?.item_description }}></td>
                          {/* <td>{workItem?.work_group || "N/A"}</td>
                          <td>{workItem?.work_item || "N/A"}</td> */}
                          <td>{workItem?.work_unit || "N/A"}</td>
                          <td>{workItem?.quantity || 0}</td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.work_item_notes?.[index]?.amount || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.work_item_notes?.[index]?.cqst || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.work_item_notes?.[index]?.sqst || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.work_item_notes?.[index]?.igst || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {workItem?.quantity *
                              data?.work_item_notes?.[index]?.amount || 0}
                          </td>
                        </tr>
                      ))}

                    <tr>
                      <td
                        colSpan={8}
                        style={{
                          background: "#fbfbfb",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        Grand Total Amount (₹)
                      </td>

                      <td style={{ background: "#fbfbfb", fontWeight: "bold" }}>
                        {data?.grand_total_amount || 0}
                      </td>
                    </tr>

                    {(!data?.work_items || data?.work_items?.length === 0) && (
                      <tr>
                        <td colSpan={8} style={{ padding: 24 }}>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <label style={styles.label}>Remarks</label>
              <TextArea
                placeholder="N/A"
                value={workItemsRemarks}
                style={{ marginBottom: 24 }}
                disabled={true}
              />
            </div>
          )}

          {terms?.terms?.length > 0 && (
            <div className="public-form-section">
              <h2 className="public-form-section-title">Terms & Conditions</h2>
              {terms?.terms?.map((term, index) => (
                <div style={{ marginBottom: '20px' }}>
                  <strong key={index}>{term?.term_name}</strong>
                  <p dangerouslySetInnerHTML={{ __html: term?.term_details }}></p>
                </div>
              ))}
            </div>
          )}

          <div>
            <Table
              columns={columns}
              dataSource={tableTerms}
              rowKey="id"
              pagination={false}
              bordered
            />

            <div style={{ marginTop: '20px' }}>
              <Table
                columns={fileUploadColumns}
                dataSource={fileTerms}
                rowKey="id"
                pagination={false}
                bordered
              />
            </div>
          </div>


          <div className="public-form-section" style={{ margin: '26px 0' }}>
            <label style={styles.label}>Remarks</label>
            <TextArea
              placeholder="N/A"
              value={termsRemarks}
              disabled={true}
            />
          </div>

          {data?.payment_schedules?.length > 0 && (
            <div className="public-form-section">
              <h2 className="public-form-section-title">Payment Schedules</h2>

              <table className="public-form-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Date</th>
                    <th style={{ width: "50%", textAlign: "left" }}>
                      Amount (₹)
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data?.payment_schedules?.length > 0 &&
                    data?.payment_schedules?.map((paymentSchedule, index) => (
                      <tr key={paymentSchedule?.id}>
                        <td className="text-center text-bold">{index + 1}</td>
                        <td>{paymentSchedule?.scheduled_date}</td>
                        <td style={{ textAlign: "left" }}>
                          {paymentSchedule?.amount}
                        </td>
                      </tr>
                    ))}

                  {(!data?.payment_schedules ||
                    data?.payment_schedules?.length === 0) && (
                      <tr>
                        <td colSpan={6} style={{ padding: 24 }}>
                          <Empty />
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>

              <label style={styles.label}>Remarks</label>
              <TextArea
                placeholder="N/A"
                value={paymentSchedulesRemarks}
                disabled={true}
              />
            </div>
          )}

          {/* <div
            className="public-form-section no-print"  // Add a specific class to target with CSS
            style={{ paddingTop: 24, display: 'flex', alignItems: 'center', gap: '10px' }}
          >
            {showsCancel && (
              <div>
                <Button onClick={config?.actions?.onBack}>Cancel</Button>
              </div>
            )}
          </div> */}

          <div className="public-form-section">
            <table style={{ width: "100%" }}>
              <tfoot>
                <tr>
                  <td colSpan={2}>
                    <div
                      style={{
                        background:
                          "url(/images/form-line.png) no-repeat center center",
                        backgroundAttachment: "cover",
                        backgroundSize: "100%",
                        height: 42,
                        marginTop: 24,
                        marginBottom: 10,
                      }}
                    />
                  </td>
                </tr>

                <tr className="text-center" style={{ fontSize: "14px" }}>
                  <td className="text-left" style={{ padding: "5px 0px" }}>
                    <img
                      title="QuickSO"
                      src="/images/form-logo.png"
                      alt="QuickSO"
                      style={{
                        height: "50px",
                        width: "auto",
                      }}
                    />
                  </td>
                  <td
                    className="text-right"
                    style={{ padding: "5px 0px", fontSize: "16px" }}
                  >
                    Copyright © 2022, All Rights Reserved by QuickSO
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: '15px', marginTop: '24px' }}>
        {/* <Button type="secondary" onClick={() => setShowsAddTermModal(true)}>
          Add Terms
        </Button> */}

        <Button type="primary" onClick={() => setShowsModal(true)}>
          Download as PDF
        </Button>
      </div>

      <Modal
        title="Print PDF"
        visible={showsModal}
        onOk={onSubmit}
        onCancel={() => setShowsModal(false)}
        destroyOnClose
      >
        <label style={{ ...styles.label, marginTop: 0 }}>Letterhead</label>
        <Select
          value={letterheadFileName}
          onChange={setLetterheadFileName}
          style={styles.select}
        >
          {letterheads?.map((letterhead, index) => (
            <Option key={index} value={letterhead?.file_name}>
              {letterhead?.name}
            </Option>
          ))}
        </Select>

        <label style={styles.label}>Preview</label>
        <img
          src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${letterheadFileName}`}
          style={styles.previewedImage}
          alt="Preview"
        />
      </Modal>

      {/* <Modal
        title="Add Term"
        visible={showsAddTermModal}
        onOk={handleAddTerm}
        onCancel={() => setShowsAddTermModal(false)}
        destroyOnClose
      >
        <ReactQuill value={newTerm} onChange={setNewTerm} />
      </Modal> */}
    </div>
  );
};

const styles = {
  container: {
    background: "white",
  },
  datePicker: {
    width: "100%",
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  loadingContainer: {
    textAlign: "center",
    padding: 24,
  },
  label: {
    fontWeight: "bold",
    display: "block",
    marginBottom: 6,
  },
  formContainer: {
    border: "2px solid #eee",
  },
  previewedImage: {
    width: "100%",
  },
  select: {
    width: "100%",
  },
};

export default Page;
